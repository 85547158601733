<template>
  <div>
    <v-tooltip bottom content-class="tooltip-bottom">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="" name="conversations" v-bind="attrs" v-on="on" icon small @click="conversationList=[];getProjectCon(true)">
          <v-badge color="error" offset-x="10" offset-y="10" top dot :value="notify">
            <v-icon class="" :color="highlight?'blue':''">mdi-comment-text-multiple</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <span>Comments</span>
    </v-tooltip>
    <v-dialog v-model="dialog" scrollable :max-width="width">
      <v-card :loading="loading" :disabled="loading" loader-height="2">
        <div class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text">{{ heading }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false" class="ml-2 white--text">
            <v-icon>mdi-close</v-icon></v-btn>
          </div>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <div ref="chatContainer" class="chat-container">
            <div v-for="(item, index) in conversationList" :key="index"  class="chat-message" >
              <div color="rgba(0,0,0,0)" flat class="mb-2 d-flex justify-end" v-if="item.user == $store.state.user.id">
                <div class="text-right line-1">
                  <v-card elevation="8" dark class="ml-2 d-inline-block rounded-lg rounded-br-0 outlined"
                    max-width="500px" color="blue">
                    <v-card-text class="py-0 px-3">
                      <div class="white--text" :id="'sent-chat-message-'+index">{{ item.value }}</div>
                    </v-card-text>
                  </v-card>

                  <div class="timeFont mt-0">
                    {{
                      $nova.formatDateTime(item.created_at, "DD MMM yyyy hh:mm A")
                    }}
                  </div>
                </div>

                <v-tooltip bottom v-if="(conversationList[index-1] || {}).user !== item.user">
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar class="ml-2" size="30" elevation="10" v-bind="attrs" v-on="on" :color="
                      item.user_detail._id == $store.state.user.id
                        ? 'indigo'
                        : 'red'
                    ">
                      <v-img
                      v-if="item.user_detail.profile_url && showprofile"
                      alt="Avatar"
                      width="30px"
                      height="30"
                      :size="30"
                      :src="item.user_detail.profile_url"
                    ></v-img>
                    <span class="white--text" v-else >{{ item.user_detail.name[0] }}</span>
                    </v-avatar>
                  </template>
                  <span>{{ item.user_detail.name }}</span>
                </v-tooltip>
                <span v-else class="mr-9"></span>
              </div>

              <div color="rgba(0,0,0,0)" flat class="d-flex mb-2" v-else>
                <v-tooltip bottom v-if="(conversationList[index-1] || {}).user !== item.user">
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar dark class="" size="30" elevation="10" v-bind="attrs" v-on="on"
                      :color=" item.user_detail._id == $store.state.user.id
                        ? 'indigo'
                        : 'indigo'
                    "
                    >
                      <v-img
                      v-if="item.user_detail.profile_url && showprofile"
                      alt="Avatar"
                      width="30px"
                      height="30"
                      :size="30"
                      :src="item.user_detail.profile_url"
                    ></v-img>
                    <span class="white--text" v-else>{{ item.user_detail.name[0] }}</span>
                    </v-avatar>
                  </template>
                  <span>{{ item.user_detail.name }}</span>
                </v-tooltip>
                <div v-else class="ml-9"></div>

                <div>
                  <v-card elevation="8" class="ml-2 d-inline-block rounded-lg rounded-bl-0 outlined" max-width="500px"
                    color="blue-grey lighten-5">
                    <v-card-text class="py-0 px-3">
                      <div class="" :id="'receive-chat-message-'+index">{{ item.value }}</div>
                    </v-card-text>
                  </v-card>
                  <div class="ml-4 timeFont line-1">
                    {{
                      $nova.formatDateTime(item.created_at, "DD MMM yyyy hh:mm A")
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="(conversationList || []).length === 0" class="title text-center my-5 grey--text">
              No Conversation added yet
            </div>
          </div>
        </v-card-text>
        <v-card-actions v-if="!closed">
          <v-card-text class="px-0">
            <div class="d-flex align-center mb-1" v-if="recurring">
              <v-checkbox label="Reucurring Comment" class="mr-2 pa-0 ma-0" dense hide-details
                v-model="recurringMsg"></v-checkbox>
              <v-tooltip right content-class="tooltip-right">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="blue-grey lighten-2" dark v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span class="text-justify">{{ recurringtext }}</span>
              </v-tooltip>
            </div>
            <div class="d-flex align-center" >
              <lb-string label="Type your message here" hidedetails @enter="sendQuery()" v-model="queryMsg" :isRequired="true"/>
              <v-btn small color="primary" class="ml-3" :loading="loading" @click="sendQuery()">
                <v-icon class="mr-2">mdi-send</v-icon>Send</v-btn>
            </div>
          </v-card-text>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "conversations",
  data() {
    return {
      dialog: false,
      loading: false,
      conversationList: [],
      queryMsg: "",
      recurringMsg: false,
    };
  },
  props: {
    getapi: {
      type: String,
      default: "",
    },
    sendapi: {
      type: String,
      default: "",
    },
    readapi: {
      type: String,
      default: "",
    },
    heading: {
      type: String,
      default: "Conversation",
    },
    recurring: {
      type: Boolean,
      default: false,
    },
    recurringtext: {
      type: String,
      default: "Requirements comments for next time",
    },
    notify: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "800",
    },
    type: {
      type: String,
      default: "string",
    },
    closed: {
      type: Boolean,
      default: true,
    },
    highlight: {
      type: Boolean,
      default: false
    },
    showprofile:{
      type:Boolean,
      default:true
    }
  },
  methods: {
    sendQuery() {
      if(this.queryMsg){
        this.loading = true;
        let dataObject = {
          type: this.type,
          value: this.queryMsg,
          status: this.status,
        };
        // console.log(dataObject);
        let successflag = false;
        this.axios
          .post(this.sendapi, { data: dataObject })
          .then((dt) => {
            if (dt.data.status === "success") {
              successflag = true;
              // this.$store.commit("sbSuccess", "successfully sended");
              this.queryMsg = "";
              
            }else throw Error(dt.data.message || "Unknown error")
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
            if(successflag) this.getProjectCon();
          });
      }
    },
    getProjectCon(mark=false) {
      this.dialog = true;
      this.loading = true;
      this.axios
        .post(this.getapi)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.conversationList = dt.data.data;
            if(mark) this.markAsRead();
          }else throw Error(dt.data.message || "Unknown error")
          // console.log(this.conversationList);
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          this.scrollToBottom();
        });
    },
    markAsRead() {
      this.axios
        .post(this.readapi)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$emit("markread");
          }else throw Error(dt.data.message || "Unknown error")
          // console.log(this.conversationList);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
        });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    }
  },
  mounted() {
    // Auto-scroll to the bottom when the component is mounted
    this.scrollToBottom();
  }
};
</script>

<style lang="scss" scoped>
.timeFont {
  font-size: 9px !important;
}

.chat-container {
  // height: 300px;
  min-height: 50px; /* Set minimum height */
  max-height: 400px;
  overflow-y: auto;
  border: 0px solid #ccc;
  padding: 2px;
}

.chat-message {
  margin-bottom: 10px;
}

@media screen and (min-width: 1024px) {
  .chat-container {
    max-height: 600px;
  }
}

/* Smaller screens (tablets) */
@media screen and (max-width: 1023px) {
  .chat-container {
    max-height: 300px;
  }
}

/* Small mobile screens */
@media screen and (max-width: 768px) {
  .chat-container {
    max-height: 200px;
  }
}
</style>

