<template>
  <div class="d-inline-block">
    <v-menu :close-on-content-click="false" open-on-hover offset-y max-width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">
          {{ icon }}
        </v-icon>
      </template>
      <v-card>
        <div v-if="text" class="pa-4">
          {{text}}
        </div>
        <slot v-else name="text" />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
    };
  },
  // created() {
  //   this.refreshData();
  // },
  // activated() {
  //   this.refreshData();
  // },
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'mdi-help-circle-outline',
    },
  },
  methods:{

  }
};
</script>

