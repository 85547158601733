import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#193441',
                fbcPrimary:'#667085',
                // primary2: '#d787ff',
                // primary3: '#eeccff',
                secondary: '#3E606F',
                // secondary2: '#7d7d7d',
                // secondary3: '#d9d9d9',
                cream: '#eef1e6',
                accent: '#91AA9D',
                // accent2: '#fcfb7c',
                // accent3: '#ffffc2',
                warning: '#ff9c07',
                info: '#00bcd4',
                success: '#4caf50',
                error: '#b22222',
                indigo: '#3F51B5',
                teal: '#027A48',
                amberamber: '#FFC107',
                lightgrey : '#475467',
                background: '#F8F8F8',
                Tabblue:'#175CD3',
                ccompliance: '#FC636B',
                crisk: '#2EB67D',
                caccounting: '#6A67CE',
                ctransaction: '#4285F4',
                btnColor: "#667085",
                lightblue:"#EFF8FF",
                lightorange:'#FFFAEB',
                orange: '#B54708',
                lightgreen:"#F2FFF8",
                lightred:'#ffc5c5',
                lightCaccounting: "#F9F5FF",
                gray:'444444'
               
            }
        },
        options: {
            customProperties: true
        }
    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
});
