<template>
    <div class="d-inline-block">
        <div class="">

            <v-btn v-bind="attrs" v-on="on" name="activity-log" @click.stop="dialog = true; loadData();" icon small>
                <v-icon>mdi-history</v-icon></v-btn>
                <span class="mr-1" @click.stop="dialog = true; loadData();">{{name}}</span>
        </div>
        <lb-dailogboxnew v-model="dialog" :heading="heading" :width="width" :loading="loading">
            <template v-slot:body>
                <div v-if="loglist.length > 0" style="max-height: 500px; overflow-y:auto">
                    <span class="font-weight-bold lightgrey--text">Recent</span>
                    <div v-for="(v, k) in loglist" :key="k">
                        <div class="d-flex my-1  pa-2  hoverable align-start">
                            <div class="subtitle-1 line-1 border-on pa-2">
                                <img :src="(config[v.type] || {}).src"/>
                            </div>
                            <div class="ml-2" style="width:100%;">
                                <div class="d-flex justify-space-between align-center line-1-2">
                                    <div small label class="font-weight-bold  "> {{ v.text || (config[v.type] ||
                                        {}).displaytext || v.type }}</div>
                                    <div style="font-size:0.65rem  !important;" class="lightgrey--text">{{
                                        $nova.formatDateTime((new
                                        Date(v.created_at)), "DD MMM yyyy hh:mm A") }}</div>
                                </div>
                                <div class="lightgrey--text">{{ (v.userid_detail || {}).name }}</div>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="grey--text py-4 text-center body-1" v-else>
                    No Log Available
                </div>
            </template>
        </lb-dailogboxnew>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            dialog: false,
            loading: false,
            loglist: [],
        };
    },
    // created() {
    //   this.refreshData();
    // },
    // activated() {
    //   this.refreshData();
    // },

    props: {
        heading: {
            type: String,
            default: 'Activity Log',
        },
        width: {
            type: String,
            default: '600',
        },
        url: {
            type: String,
        },
        config: {
            default: {}
        },
        contentClass: {
            type: String,
            default: 'tooltip-bottom'
        },
        name:
        {
            type: String,
            default:""
        }
    },

    methods: {
        loadData() {
            this.loading = true;
            this.loglist = [];
            if (this.url) {
                this.axios.post(this.url).then(dt => {
                    if (dt.data.status === "success") {
                        this.loglist = dt.data.data;
                        // console.log(this.loglist);
                    } else throw new Error("Error loading information");
                }).catch(err => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                    console.log(err);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
    }
};
</script>

<style scoped>
.timeline-line {
    min-width: 3px;
    max-width: 3px;
}
</style>