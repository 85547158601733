<template>
  <div>
    <div class="d-flex align-center">
      <span class="pr-1">{{ label || "List" }}: </span>
      <span class="" v-if="!disabled">
        <v-tooltip bottom content-class="tooltip-bottom">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" @click="dialog=true" icon class="shadow-off" small>
              <v-icon small>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <span>Add Item(s)</span>
        </v-tooltip>
      </span>
      <v-spacer></v-spacer>
      <span class="grey--text pl-1 "
        >{{ content.length }} Added</span
      >
    </div>
    <v-dialog
      :max-width="width"
      v-model="dialog"
      transition="dialog-transition"
      ref="dialog"
      eager
      @input="setFocus()"
    >
      <v-card elevation="0" flat>
        <v-card-text class="title pt-3">
          <div class="d-flex align-center">
            <span class="flex-grow-1">Add new</span>
            <span class="caption">{{ content.length }} Added</span>
          </div>
        </v-card-text>
        <v-card-text class="py-0">
          <lb-number
            ref="element"
            v-if="type === 'numberarray'"
            v-model="tempContent"
            :hide-details="hidedetails"
            :disabled="disabled"
          />
          <lb-date
            ref="element"
            v-else-if="type === 'datearray'"
            v-model="tempContent"
            :hide-details="hidedetails"
            :disabled="disabled"
          />
          <lb-month
            ref="element"
            v-else-if="type === 'montharray'"
            v-model="tempContent"
            :hide-details="hidedetails"
            :disabled="disabled"
          />
          <lb-year
            ref="element"
            v-else-if="type === 'yeararray'"
            v-model="tempContent"
            :hide-details="hidedetails"
            :disabled="disabled"
          />
          <lb-time
            ref="element"
            v-else-if="type === 'timearray'"
            v-model="tempContent"
            :hide-details="hidedetails"
            :disabled="disabled"
          />
          <lb-daterange
            ref="element"
            v-else-if="type === 'daterangearray'"
            v-model="tempContent"
            :hide-details="hidedetails"
            :disabled="disabled"
          />
          <lb-timerange
            ref="element"
            v-else-if="type === 'timerangearray'"
            v-model="tempContent"
            :hide-details="hidedetails"
            :disabled="disabled"
          />
          <lb-string
            ref="element"
            v-else-if="type === 'stringarray'"
            v-model="tempContent"
            :hide-details="hidedetails"
            :disabled="disabled"
          />
        </v-card-text>
        <v-card-text class="text-right">
          <v-btn
            text
            class="mr-1"
            small
            @click="
              dialog = false;
              updateValue();
            "
            >close</v-btn
          >
          <v-btn
            small
            class="mr-1"
            elevation="0"
            color="primary"
            @click="
              addToList();
              dialog = false;
            "
            :disabled="disableAddBtn"
            >Add</v-btn
          >
          <v-btn
            small
            elevation="0"
            color="primary"
            @click="
              addToList();
              setFocus(0);
            "
            :disabled="disableAddBtn"
            >Add more</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-if="display === 'list'">
      <div
        v-for="(v, k) in formattedContent"
        :key="k"
        class="hoverable"
      >
        <div class="d-flex align-start px-2">
          <div class="">{{ v }}</div>
          <v-spacer></v-spacer>
          <v-tooltip bottom content-class="tooltip-bottom" v-if="!disabled">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small v-bind="attrs" v-on="on"
                class="pl-1 pt-1 cursor-pointer"
                @click="deleteItem(k)"
                >mdi-close-circle</v-icon
              >
            </template>
            <span>Remove Item</span>
          </v-tooltip>
        </div>
        <v-divider></v-divider>
      </div>
    </div>
    <div v-else>
      <v-chip small v-for="(v, k) in formattedContent" :key="k" class="mb-1 mr-1">
        {{ v }}
        <v-tooltip bottom content-class="tooltip-bottom" v-if="!disabled">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small right @click="deleteItem(k)">mdi-close-circle</v-icon>
          </template>
          <span>Remove Item</span>
        </v-tooltip>
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    // lbstring, lbnumber, lbdate, lbtime, lbdaterange, lbtimerange
  },
  created() {
     this.setTemp();
    this.setFormatted();
    if (this.type === "stringarray") this.width = "580px";
    this.updateValue();
  },
  activated() {
    this.setTemp();
    this.setFormatted();
    if (this.type === "stringarray") this.width = "580px";
    this.updateValue();
  },
  data: () => ({
    dialog: false,
    content: [],
    tempContent: "",
    formattedContent: [],
    width: "290px",
  }),
  props: {
    error: {
      type: [String, Array],
    },
    label: {
      type: String,
    },
    value: {
      type: Array,
    },
    type: {
      type: String,
    },
    display: {
      type: String,
    },
    hidedetails: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowrepeat: {
      type: Boolean,
      default: false,
    },
    // required: {
    //   type: Boolean,
    //   default: false
    // }
  },
  methods: {
    setFocus: function (t = 400) {
      setTimeout(() => {
        if (this.$refs.element) this.$refs.element.setFocus();
      }, t);
    },
    setTemp: function () {
      this.content = this.value || [];
      let arrforlist = ["daterangearray", "timerangearray"];
      if (arrforlist.indexOf(this.type) > -1) this.tempContent = [];
      else this.tempContent = "";
    },
    setFormatted: function () {
      this.formattedContent = [];
      this.content.forEach((el) => {
        this.formattedContent.push(this.formatData(el));
      });
    },
    formatData: function (d) {
      let r = d;
      if (this.type === "daterangearray" || this.type === "datearray") {
        let fc = this.$nova.formatDate(d);
        if (fc instanceof Array) r = fc.join(" - ");
        else r = fc;
      } else if (this.type === "timerangearray" || this.type === "timearray") {
        let fc = this.$nova.formatTime(d);
        if (fc instanceof Array) r = fc.join(" - ");
        else r = fc;
      }
      return r;
    },
    updateValue: function () {
      this.$emit("input", this.content);
      this.$emit("change", this.content);
    },
    addToList: function () {
      let fc = this.formatData(this.tempContent);
      if (fc !== "") {
        if (this.content.indexOf(this.tempContent) === -1 || this.allowrepeat) {
          this.content.push(this.tempContent);
          this.formattedContent.push(fc);
        }
        this.updateValue();
        this.setTemp();
      }
    },
    deleteItem: function (k) {
      this.content.splice(k, 1);
      this.formattedContent.splice(k, 1);
      this.updateValue();
    },
  },
  computed: {
    disableAddBtn() {
      return (
        !(this.tempContent && this.tempContent !== false && this.tempContent.trim() !== '') ||
        (this.tempContent instanceof Array
          ? this.tempContent.length === 0 || this.tempContent.join("") === ""
          : false)
      );
    },
  },
  watch: {
    value: function () {
      this.setTemp();
      this.setFormatted();
    },
  },
};
</script>