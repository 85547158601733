<template>
  <v-dialog
    max-width="290px"
    v-model="dialog"
    open-on-focus
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        ref="element"
        dense
        outlined
        :label="label || 'Date Range'"
        clearable
        v-on="on"
        :error-messages="error"
        class=""
        :value="formattedRangevalue"
        @click:clear="
          content = [];
          tempContent = [];
          updateValue();
        "
        :hide-details="hidedetails"
        :disabled="disabled"
      ></v-text-field>
    </template>
    <v-card elevation="0" flat tile>
      <v-card-text class="pa-0">
        <v-date-picker
          elevation="0"
          v-model="tempContent"
          range
          color="primary"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            small
            @click="
              dialog = false;
              tempContent = content;
              updateValue();
            "
            >close</v-btn
          >
          <v-btn
            small
            elevation="0"
            color="primary"
            @click="
              content = tempContent;
              dialog = false;
              updateValue();
            "
            :disabled="tempContent.length != 2"
            >ok</v-btn
          >
        </v-date-picker>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  activated() {
    this.setValue();
    this.updateValue();
  },
  created() {
    this.setValue();
    this.updateValue();
  },
  data: () => ({
    formattedvalue: "",
    dialog: false,
    content: [],
    tempContent: [],
  }),
  props: {
    error: {
      type: [String, Array],
    },
    label: {
      type: String,
    },
    value: {
      type: Array,
    },
    hidedetails: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // required: {
    //   type: Boolean,
    //   default: false
    // }
  },
  methods: {
    setValue: function () {
      let v = [];
      if (this.value instanceof Array) {
        for (let i = 0; i < this.value.length; i++) {
          const el = this.value[i];
          if (!isNaN(new Date(el))) v.push(this.$nova.formatDateISO(el));
        }
      }
      this.content = v;
    },
    updateValue: function () {
      this.$emit("input", this.content);
      this.$emit("change", this.content);
    },
    setFocus: function () {
      this.$nextTick(() => {
        this.$refs.element.$el.querySelector("input").focus();
      });
    },
    setBlank: function () {
      this.content = [];
      this.tempContent = [];
    },
  },
  computed: {
    formattedRangevalue() {
      let v = this.$nova.formatDate(this.content);
      let dt = v.toString().split(",");
      if (dt.length === 2) return dt.join(" - ");
      else {
        this.setBlank();
        return "";
      }
    },
  },
  watch: {
    value: function () {
      this.setValue();
    },
  },
};
</script>