<template>
  <div v-if="type==='horizontal'" :style="`height: ${this.height}px !important; background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(210,210,210,1) 50%, rgba(255,255,255,0) 100%);`"></div>
  <div v-else :style="`height:100% !important; width: ${this.width}px !important; background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(210,210,210,1) 50%, rgba(255,255,255,0) 100%);`"></div>
</template>

<script>
  export default {
    name: 'lbdivider',
    components: {
    },
    methods: {
    },
    props: {
      height: {
        type: Number,
        default: 1
      },
      width: {
        type: Number,
        default: 1
      },
      type: {
        type: String,
        default: 'horizontal'
      }
    },
  }
</script>
