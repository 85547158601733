<template>
  <div>
    <div v-if="fileList.length === 0 || multiple || true">
      <v-file-input
        :accept="accept"
        class="d-none filebtn"
        ref="fileinput"
        :label="label"
        v-model="content"
        :multiple="multiple"
        @change="updateValue()"
        :hide-details="hidedetails"
        :disabled="disabled"
      ></v-file-input>
      <div
        :class="{
          'uploaddropbox drop-container px-2 py-1 uploadbox bg-white': drag,
          uploadboxerror: !!error && !content,
        }"
        @drop.prevent="(e) => dropFiles(e)"
        @dragover.prevent
      >
        <div>
          <div class="drop-container-default">
            <div class="d-flex align-center">
              <span v-if="label" class="mr-4">{{ label }}</span>
              <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    :loading="loading"
                    v-if="(!content || reupload) && !disabled"
                    @click="clickupload()"
                    class="shadow-off"
                    elevation="0"
                    small
                    :icon="icononly"
                    :outlined="!icononly"
                    :text="!icononly"
                  >
                    <template v-slot:loader>
                      <v-progress-circular
                        indeterminate
                        size="17"
                        width="2"
                        class="mr-1"
                      ></v-progress-circular>
                      <span>{{ Number(loadingpercent || 0).toFixed(0) }}%</span>
                    </template>
                    <v-icon small :left="!icononly">mdi-upload</v-icon><span v-if="!icononly">Upload</span>
                  </v-btn>
                </template>
                <span>Upload File</span>
              </v-tooltip>
              <v-spacer v-if="displayname"></v-spacer>
              <template v-if="displayname">
                <div
                  v-for="(v, k) in fileList"
                  :key="k"
                  :class="{ 'display-ib': !multiple, 'ml-2': true }"
                >
                  <div>
                    <span class="blue--text text--darken-4 pr-3 py-1">{{
                      v.name
                    }}</span>
                    <span
                      class="grey--text text--darken-1 caption"
                      v-if="v.size > 1024 * 1024"
                      >{{ (v.size / (1024 * 1024)).toFixed(2) }}MB</span
                    >
                    <span class="grey--text text--darken-1 caption" v-else
                      >{{ (v.size / 1024).toFixed(0) }}KB</span
                    >
                    <v-btn
                      v-if="remove"
                      class="ml-2"
                      small
                      icon
                      @click="removeFile(k)"
                      ><v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="id">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="shadow-off"
                    icon
                    @click="$nova.downloadFile(id)"
                  >
                    <v-icon>mdi-download</v-icon></v-btn
                  >
                </template>
                <span>Download</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="error--text" style="min-height: 20px" v-if="!hidedetails">
        <div v-if="!content">
          {{ error instanceof Array ? error[0] || "" : error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  activated() {
    this.setValue();
    // this.updateValue();
  },
  created() {
    this.setValue();
    // this.updateValue();
  },
  data: () => ({
    content: null,
  }),
  props: {
    error: {
      type: [String, Array],
    },
    label: {
      type: String,
      default: "File",
    },
    id: {
      type: String,
      default: "",
    },
    value: {
      // type: String
      default: null,
    },
    accept: {
      type: String,
      default: "",
    },
    loadingpercent: {
      type: [String, Number],
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    reupload: {
      type: Boolean,
      default: true,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    drag: {
      type: Boolean,
      default: true,
    },
    hidedetails: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    displayname: {
      type: Boolean,
      default: true,
    },
    icononly: {
      type: Boolean,
      default: false,
    },
    // required: {
    //   type: Boolean,
    //   default: false
    // }
  },
  methods: {
    setValue: function () {
      if (this.multiple) {
        if (this.value instanceof Array) this.content = this.value;
        else this.content = [];
      } else {
        if (this.value instanceof File) this.content = this.value;
        else this.content = null;
      }
      // console.log("called 1")
      // this.updateValue();
    },
    updateValue: function () {
      this.$emit("input", this.content);
      this.$emit("change", this.content);
    },
    setFocus: function () {
      this.$nextTick(() => {
        this.$refs.element.$el.querySelector("input").focus();
      });
    },
    dropFiles: function (d) {
      let droppedFiles = d.dataTransfer.items;

      if (droppedFiles) {
        let fl = [...droppedFiles];
        let ec = fl.length;
        if (!this.multiple) ec = 1;
        for (let i = 0; i < ec; i++) {
          let el = fl[i];
          if (el.webkitGetAsEntry().isFile) {
            el.webkitGetAsEntry().file((fil) => {
              if (!this.multiple) this.content = fil;
              else this.content.push(fil);
              this.updateValue();
            });
          }
        }
      }
    },
    clickupload: function () {
      this.$refs.fileinput.$el.querySelector("input").click();
    },
    removeFile: function (k) {
      if (this.multiple) this.content.splice(k, 1);
      else this.content = null;
      this.updateValue();
    },
  },
  computed: {
    fileList: function () {
      let d = [];
      if (this.content instanceof Array) d = this.content;
      else if (this.content instanceof File) d = [this.content];

      return d;
    },
  },
  watch: {
    value: function () {
      this.setValue();
    },
    // loadingpercent: function(e){
    //   console.log("fileee", e);
    // }
  },
};
</script>

<style scoped>
.uploadbox {
  border: 1px solid #999;
  border-radius: 5px;
}
/* .uploadbox .v-btn{
  color: #888;
} */
.uploadboxerror {
  border: 2px solid var(--v-error-base) !important;
}
.uploadboxerror *,
.uploadboxerror .v-btn * {
  color: var(--v-error-base) !important;
}
</style>