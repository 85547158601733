<template>
    <div>
      <!-- <span @click="openDialog(true)">
        <slot name="button" />
      </span> -->
      <v-dialog v-model="value" :max-width="width" scrollable persistent>
        <v-card :loading="loading" :disabled="loading" loader-height="2" >
          <v-card-title class=" align-center py-1 px-4 border-on">
            <span class="subtitle-2 font-weight-bold">{{ heading }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="openDialog(false)" class="ml-2 "><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-actions >
            <slot name="tabs" />
          </v-card-actions>
          <v-card-text class="pt-3">
            <slot name="body" />
          </v-card-text>
          <v-card-actions >
            <slot name="actions" />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    name: "DialogBox",
    data: function(){
      return {
        dialog: false,
      }
    },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      heading: {
        type: String,
        default: "",
      },
      width: {
        type: String,
        default: "",
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    created(){
      this.dialog = this.value;
    },
    activated(){
      this.dialog = this.value;
    },
    methods: {
      openDialog(v) {
        this.$emit("input", v);
        this.$emit("change", v);
      },
    },
    watch: {
      dialog(v) {
        this.openDialog(v);
      },
      value(v) {
        this.dialog = v;
      }
    }
  };
  </script>

  <style lang="scss">
.v-card-text
{
  width: 100%;
  height: 100%;
}
</style>
  