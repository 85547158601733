<template>
  <div class="text-center">
    <img src="/img/loader.gif"  alt="loader-image"/><br/>
  </div>
</template>

<script>
  export default {
    data: () => ({
    }),
    methods: {
    }
  }
</script>