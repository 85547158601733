<template>
  <v-app id="app">
    <div class="bg-design-box bg-design-2"></div>
    <div class="bg-design-box bg-design-3"></div>
    <v-main class="bg-image">
      <v-container fluid class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6" lg="4">
            <div class="text-center mb-4">
              <a href="//consark.ai">
                <img
                  src="/img/consark/logo.png"
                  class=""
                  style="max-width: 300px"
                  alt="consark-logo"
                />
              </a>
              <div class="body-1 font-weight-bold">
                Unified Controllership Platform
              </div>
            </div>
            <div v-if="alreadylogin">
              <loader />
            </div>
            <div v-else-if="logintype === 'okta'">
              <v-btn
                color="primary ma-0"
                block
                elevation="0"
                @click="oktaLogin()"
                >Login using Okta</v-btn
              >
            </div>
            <div v-else-if="logintype === 'normal'">
              <v-card
                :class="{
                  'mb-4 shadow2-a pb-1 px-1': true,
                  'px-3': $vuetify.breakpoint.smAndUp,
                }"
                loader-height="2"
                :loading="loading"
                :disabled="loading"
              >
                <!-- <v-card-text class="pb-1">
                  <div class="title">Provide your login details below</div>
                </v-card-text> -->
                <v-card-text class="mt-3">
                  <v-alert
                    type="error"
                    border="left"
                    v-if="msg != ''"
                    text
                    class="py-2"
                    >{{ msg }}</v-alert
                  >
                  <lb-string
                    v-model="username"
                    label="Username/Email"
                    prependiconinner="mdi-shield-account"
                  ></lb-string>
                  <v-text-field
                    type="password"
                    v-model="password"
                    label="Password"
                    class=""
                    dense
                    outlined
                    prepend-inner-icon="mdi-key-variant"
                    prefix=""
                    suffix=""
                    hide-details
                  >
                  </v-text-field>
                  <div class="text-right mt-2">
                    <v-btn text small to="/forgotpassword" class="shadow-off">Forgot Password?</v-btn>
                    <v-btn text small to="/selfservice" class="shadow-off">Self Service</v-btn>
                  </div>
                </v-card-text>
              </v-card>
              <div class="">
                <v-btn
                  color="primary ma-0"
                  block
                  elevation="0"
                  @click="submitLogin()"
                  >Login securely</v-btn
                >
              </div>

            </div>
            <div v-else>
              <v-card
                :class="{
                  'mb-4 shadow2-a pb-1 px-1': true,
                  'px-3': $vuetify.breakpoint.smAndUp,
                }"
                loader-height="2"
                :loading="loading"
                :disabled="loading"
              >
                <!-- <v-card-text class="pb-1">
                  <div class="title">Provide your login details below</div>
                </v-card-text> -->
                <v-card-text class="mt-3">
                  <v-alert
                    type="error"
                    border="left"
                    v-if="msg != ''"
                    text
                    class="py-2"
                    >{{ msg }}</v-alert
                  >
                </v-card-text>
              </v-card>
            </div>
            <div class="text-center mt-4">
              <!-- remove logo <div class="Caption font-weight-bold">Powered By</div>
              <img
                src="/img/conserve/logotext-no-bg.webp"
                class=""
                style="max-width: 200px"
                alt="consark-image-background-image"
              /> -->
              <div class="text-center mt-2"> 
                  <a href="https://consark.ai/policies/termsofuse/" target="_blank">
                    <v-btn class="shadow-off primary--text mr-2" outlined small>Terms of Use & Service</v-btn>
                </a>
                  <a href="https://consark.ai/policies/privacypolicy/" target="_blank">
                <v-btn class="shadow-off primary--text" outlined small
                  >Privacy Policy</v-btn>
                  </a>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import loader from "../common/Loader";

export default {
  name: "login",
  components: {
    loader,
  },
  data: () => ({
    username: "",
    password: "",
    loading: false,
    alreadylogin: true,
    logintype: '',
    msg: "",
  }),
  created() {
    if(this.$route.query.token) this.verifyToken();
    else this.checkLogin();
  },
  mounted() {
    
    window.addEventListener('keydown', this.handleEnterKey);
  },
  beforeDestroy() {
    
    window.removeEventListener('keydown', this.handleEnterKey);
  },
  methods: {
    handleEnterKey(event) {
      if (event.key === 'Enter' && this.logintype === 'normal') {
        this.attemptLogin();
      }
    },
    attemptLogin() {
      if (this.username && this.password) {
        this.submitLogin();
      }
    },
    verifyToken() {
      this.loading = true;
      this.$store.commit("addLoading");
      this.axios
        .post("/v2/auth/okta/verifycallback", {})
        .then((x) => {
          if (x.data.status == "success") {
            this.$store.commit("setUser", x.data.data);
            this.$store.commit("changeLogin", true);
            this.msg = "";
            let routeData = {
              path: this.$route.path,
              query: {...this.$route.query.cid},
              hash: this.$route.hash,
            };
            this.$router.push(routeData);
          } else {
            this.msg = x.data.message || "unknown error";
            this.$store.commit("changeLogin", false);
            this.alreadylogin = false;
            // console.log(process.env.VUE_APP_AUTH_TYPE);
            if(process.env.VUE_APP_AUTH_TYPE === 'okta') this.logintype = 'okta';
            else this.logintype = 'normal';
          }
        }).catch((e) => {
          this.msg = "cannot connect to server";
          console.log(e.message);
          this.alreadylogin = false;
        }).finally(() => {
          this.$store.commit("removeLoading");
          this.loading = false;
        });
    },
    checkLogin() {
      this.loading = true;
      this.$store.commit("addLoading");
      this.axios
        .post("/v2/auth/checklogin", {})
        .then((x) => {
          if (x.data.status == "success") {
            this.$store.commit("setUser", x.data.data);
            this.$store.commit("changeLogin", true);
            this.msg = "";
          } else {
            if(x.data.message !=="Not logged in"){
              this.msg = x.data.message || "unknown error";
            }
            this.$store.commit("changeLogin", false);
            this.alreadylogin = false;
            // console.log(process.env.VUE_APP_AUTH_TYPE);
            if(process.env.VUE_APP_AUTH_TYPE === 'okta') this.logintype = 'okta';
            else this.logintype = 'normal';
          }
        }).catch((e) => {
          this.msg = "cannot connect to server";
          console.log(e.message);
          this.alreadylogin = false;
        }).finally(() => {
          this.$store.commit("removeLoading");
          this.loading = false;
          console.log(localStorage)
          if(localStorage.getItem('commentary_custom_filter')){
            localStorage.setItem('commentary_custom_filter','')
            localStorage.setItem("commentary_custom_filter_toggle", false);
          }
        });
    },
    submitLogin() {
      this.loading = true;
      this.axios
        .post("/v2/auth/local", {
          username: this.username,
          password: this.password,
        })
        .then((x) => {
          if (x.data.status == "success") {
            this.$store.commit("changeLogin", true);
            console.log(x.data.data,"DDD");
            this.$store.commit("setUser", x.data.data);
            this.checkLogin();
          } else {
            if (x.data.message == "")
              this.msg = "Unable to login. Please check your login details";
            else this.msg = x.data.message;
          }
        })
        .catch((e) => {
          console.log(e.message || "error logging in");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    oktaLogin() {
      window.location.href = process.env.VUE_APP_API_URL+"/v2/auth/okta";
    }
  },
};
</script>
<style lang="scss" scoped>
.logo-image {
  max-width: 100%;
}
// .bg-image{
//   background: url('/img/conserve/bg.webp') no-repeat center center fixed;
//   background-size: cover;
// }

@keyframes bg-design-1-animation {
  0% {
    bottom: 0;
    left: -10vw;
    transform: rotate(20deg);
  }
  40% {
    bottom: 2vh;
    left: -10vw;
    transform: rotate(23deg);
  }
  70% {
    bottom: 5vh;
    left: -12vw;
    transform: rotate(18deg);
  }
  100% {
    bottom: 0;
    left: -10vw;
    transform: rotate(20deg);
  }
}
.bg-design-1 {
  animation-name: bg-design-1-animation;
  animation-duration: 15s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  background-color: rgba(252, 99, 107, 0.2);
  height: 50vh;
  width: 20vw;
  bottom: -10vh;
  border-radius: 25px;
  left: -10vw;
  transform: rotate(20deg);
}
@keyframes bg-design-2-animation {
  0% {
    bottom: -10vh;
    left: -5vw;
    transform: rotate(40deg);
  }
  40% {
    bottom: -12vh;
    left: -5vw;
    transform: rotate(45deg);
  }
  70% {
    bottom: -8vh;
    left: -9vw;
    transform: rotate(35deg);
  }
  100% {
    bottom: -10vh;
    left: -5vw;
    transform: rotate(40deg);
  }
}
.bg-design-2 {
  animation-name: bg-design-2-animation;
  animation-duration: 12s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  background-color: var(--v-primary-base);
  opacity: 0.2;
  height: 30vh;
  width: 30vw;
  bottom: -15vh;
  border-radius: 25px;
  left: -5vw;
  transform: rotate(40deg);
}
@keyframes bg-design-3-animation {
  0% {
    top: -45vh;
  }
  40% {
    top: -40vh;
  }
  70% {
    top: -50vh;
  }
  100% {
    top: -45vh;
  }
}
.bg-design-3 {
  animation-name: bg-design-3-animation;
  animation-duration: 22s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  background-color: rgba(46, 182, 125, 0.2);
  height: 100vh;
  width: 60vw;
  top: -45vh;
  border-radius: 25px;
  right: -40vw;
  transform: rotate(-40deg);
}
</style>