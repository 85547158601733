<template>
  <v-autocomplete ref="element"
    :items="yearlist" outlined dense :label="label || 'Year'"
    :error-messages="error" v-model="content" @change="updateValue()" :hide-details="hidedetails" :disabled="disabled"
  ></v-autocomplete>
</template>

<script>
  export default {
    activated() {
      this.setValue();
      this.updateValue();
      this.yearlist = this.yearlistdefault;
      if(this.items instanceof Array) {
        if(this.items.length > 0) this.yearlist = this.items;
      }
    },
    mounted() {
      let autocompleteInput = this.$refs.element.$el.querySelector('input');
      autocompleteInput.addEventListener('focus', this.onFocus, true);
    },
    created() {
      this.yearlist = this.yearlistdefault;
      if(this.items instanceof Array) {
        if(this.items.length > 0) this.yearlist = this.items;
      }
      this.setValue();
      this.updateValue();
    },
    data: () => ({
      content: "",
      yearlist: [],
      yearlistdefault: Array((new Date().getFullYear()+100) - 1990).fill().map((v, i) => i + 1901),
    }),
    props: {
      error: {
        type: [String, Array],
      },
      label: {
        type: String,
      },
      items: {
        type: Array,
        default: function () {
          return [];
        }
      },
      value: {
        // type: String
      },
      hidedetails: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
      // required: {
      //   type: Boolean,
      //   default: false
      // }
    },
    methods: {
      setValue: function(){
        this.content = Number(this.value) || '';
        console.log("yearrr", this.value, this.content);
      },
      updateValue: function () {
        this.$emit('input', this.content);
        this.$emit('change', this.content);
      },
      setFocus: function(){
        this.$nextTick(() => {
          this.$refs.element.$el.querySelector('input').focus();
        })
      },
      onFocus () {
        this.$refs.element.isMenuActive = true  // open item list
      }
    },
    computed:{
    },
    watch: {
      value: function(){
        this.setValue();
      }
    }
  }
</script>