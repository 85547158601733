import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'

import routerobj from './router';
import store from './store';
import globals from './plugins/globals';
import axioss from 'axios';
import VueAxios from 'vue-axios';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import '@/views';

// import test  from './modules/pbc/store';
//   console.log(test);

const API_URL = process.env.VUE_APP_API_URL;

Vue.config.productionTip = false

// Configure axios & its middlewares
var axios = axioss.create({
  baseURL: API_URL,
  withCredentials: true
});
// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.data = config.data || {};
  if(!config.data.client) config.data.client = store.state.client || "";
  return config;
}, function (error) {
  console.log(error);
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  // console.log("axios", store);
  // cookie = response.headers.cookie;
  // console.log(response.headers['set-cookie'])
  if(["UL403"].indexOf((response.data || {}).code) > -1 || response.data?.message === "unauthorized"){
    store.commit("changeLogin", false);
  }
  return response;
}, function (error) {
  // Do something with response error
  console.log(error);
  return Promise.reject(error);
});

// Setup other middlewares
Vue.use(VueAxios, axios);
Vue.use(PerfectScrollbar);


let router = routerobj.router;
axios.post("/v2/profile/getdetails").then(dt => {
  if(dt.data.status === "success"){
    let rdata = (dt.data.data || [])[0] || {};
    return store.commit("setSubscription", rdata.subscription || {});            
  }else{
    console.log("Error fetching subscription list");
  }
}).catch(err => {
  console.log(err);
}).then(() => {
  return addCustomModules();
}).finally(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
  Vue.use(globals);
});


// To register the custom modules
async function addCustomModules(){
  // module name, subscription product name, cart group
  await registerCustomeModule("admin", ["admin"], "others");
  await registerCustomeModule("home", ["home"], "ucp");
  await registerCustomeModule("pbc", ["pbc"], "risk");
  await registerCustomeModule("varianceanalysis", ["varianceanalysis"], "accounting");
  await registerCustomeModule("financialclose", ["financialclose"], "accounting");
  await registerCustomeModule("tds", ["tds_ar","tds_ap","tds_monthly"], "compliance");
  await registerCustomeModule("earnout", ["earnout"], "transaction");
  await registerCustomeModule("bot", ["bot"], "others");
  await registerCustomeModule("ia", ["ia"], "risk");
  await registerCustomeModule("helpdesk", ["helpdesk"], "others");
  await registerCustomeModule("flux", ["flux"], "others");
}

async function registerCustomeModule(name, subscription, solution){
  const {default: modname} = await import('./modules/'+name+'/store');
  store.registerModule("m_"+name, modname);
  const {default: routes} = await import('./modules/'+name+'/route');
  router.onReady(() => {
    let routelist = registerCustomRouter(routes, name, subscription, solution)
    for (const i of routelist) {
      router.addRoute(i);
    }
  })
  try{
    const {default: config} = await import('./modules/'+name+'/config');
    config.init();
  }catch(err){return}
}

function registerCustomRouter(routes, moduleName, subscription, solution){
  let rt = [];
  for (const el of routes) {
    let nrt = {meta: {}};
    if(el.path) nrt = routerobj.getRouteData(moduleName, solution, el.path, el.component, el.meta, el.function, el.noaccess);
    if(el.children) nrt.children = registerCustomRouter(el.children, moduleName, subscription, solution);
    nrt.meta["subscription"] = subscription;
    nrt.meta["solution"] = solution;
    nrt.meta["modulename"] = moduleName;
    rt.push(nrt);
  }
  return rt;
}


