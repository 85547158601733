<template>  
  <v-app>
    <v-progress-linear v-if="$store.state.progressLoading" 
      height="2"
      indeterminate
      color="indigo"
      absolute
      style="top:0px;z-index:1000"
    ></v-progress-linear>
    
    
    <div class="bg-design-box bg-design-2"></div>
    <div :class="`bg-design-box bg-design-3 bgcolor-${($route.meta || {}).color}-20`"></div>
    
    <div v-if="!clientload" class="all-height d-flex align-center justify-center">
      <div>
        <div class="project-title display-3 pb-5 text-center">
          <img src="/img/consark/logo.png" class="logo-image" alt="logo-image"/>
        </div>
        <loader />
      </div>
    </div>

    <v-navigation-drawer app class="nav-drawer" touchless fixed v-if="clientload" v-model="showNavgation" :mini-variant="navminivarient" permanent width="240">
      <div class="navigation-container d-flex flex-column">
        <!-- <div v-if="navminivarient" class="white shadow-on cursor-pointer flex-shrink-0" @click="$nova.gotoLink({path: '/'})"> -->
        <div v-if="navminivarient" class="white shadow-on cursor-pointer flex-shrink-0">
          <div style="height:56px" class="d-flex justify-center align-center py-3 px-3">
            <img width="100%" src="/img/consark/logo_icon.webp"  alt="back-ground image"/>
          </div>
        </div>
        <!-- <div v-else class="white shadow-on cursor-pointer flex-shrink-0" @click="$nova.gotoLink({path: '/'})"> -->
        <div v-else class="white shadow-on cursor-pointer flex-shrink-0">
          <div style="height:56px" class="d-flex align-center justify-center py-4 px-4">
            <img width="70%" :aspect-ratio="16 / 9" src="/img/consark/logo.png"  alt="back-ground image"/>
          </div>
        </div>
        <div v-if="(($store.state.menu || [])[0] || {}).product && !navminivarient" :class="`pa-1 mb-0 ${(($store.state.menu || [])[0] || {}).bgcolor} subtitle-2 text-center white--text mr-2 mt-2 radius-right`">{{(($store.state.menu || [])[0] || {}).title}}</div>
        
        <div class="d-flex flex-column pb-0 pt-2 pr-2" style="overflow: hidden;">
          <div class="scroller">
            <!-- <v-card flat tile v-if="$vuetify.breakpoint.mdAndDown" class="sticky" :color="$vuetify.breakpoint.mdAndDown?'white':'transperant'" border="0">
              <v-card-title class="font-weight-thin display-1">
                <template>Navigation</template>
                <v-spacer />
                
              </v-card-title>
            </v-card> -->



            <div class="menu-sidebar">
              <v-list class="mb-6">
                <v-list-item-group active-class="primary--text">
                  <v-slide-x-transition class="py-0" group>
                    <template v-for="(v,k) in $store.state.menu">
                      <div v-if="v.header && !navminivarient" :key="k" class="border-left-primary caption font-weight-bold mt-2 py-1 pl-2">
                        <div>{{v.title}}</div>
                      </div>
                      <div v-else-if="v.subheader && !navminivarient" :key="k" class="caption font-weight-bold py-1 pl-4 mt-1">
                        <div>{{v.title}}</div>
                      </div>
                      <lb-divider v-if="v.header && navminivarient" :key="k" class="my-2"></lb-divider>
                      <lb-divider v-if="v.subheader && navminivarient" :key="k" class="my-2"></lb-divider>
                      <div v-else-if="v.divider" :key="k" class="my-2"><lb-divider></lb-divider></div>
                      <div v-else-if="v.button" :key="k" class="pb-4 pl-2">
                        <v-tooltip v-if="navminivarient" right content-class="tooltip-right">
                          <template v-slot:activator="{ on, attrs }" >
                            <v-btn v-bind="attrs" v-on="on" color="primary" fab small @click="$nova.gotoLink({path: v.href})">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>{{v.title}}</span>
                        </v-tooltip>
                        <v-btn v-else color="primary" small block @click="$nova.gotoLink({path: v.href})">
                          <v-icon left>mdi-plus</v-icon>
                          <span>{{v.title}}</span>
                        </v-btn>
                      </div>
                      
                      <v-list-item dense v-else-if="!v.product && v.href" :key="k" :class="`${(navminivarient)?'text-center px-3':'sb-link-slide'} ml-${2+((navminivarient?0:v.extrapad) || 0)} sb-link py-0`" :to="$nova.gotoLink({path: v.href, hash: v.hash}, true)" transition="scroll-x-transition">
                        <div class="py-0">
                          <div class="py-0">
                            <v-tooltip v-if="navminivarient" right content-class="tooltip-right">
                              <template v-slot:activator="{ on, attrs }" >
                                <div v-bind="attrs" v-on="on" class="black--text d-flex align-center justify-center">
                                  <v-icon class="grey--text text--darken-3">{{v.icon}}</v-icon>
                                </div>
                              </template>
                              <span v-if="v.edit===true">{{ $store.state.LocationHeader ? $store.state.LocationHeader  :'Location' }} </span> 
                              <span v-else>{{v.title}}</span>
                            </v-tooltip>
                            <div v-else class="d-flex align-center black--text">
                              <v-icon class="nav-icon grey--text text--darken-3 pa-1 mr-2">{{v.icon}}</v-icon>
                              <span v-if="v.edit===true">{{ $store.state.LocationHeader ? $store.state.LocationHeader  :'Location' }} </span> 
                              <span v-else>{{v.title}}</span>
                            </div>
                          </div>
                        </div>
                      </v-list-item>
                    </template>
                  </v-slide-x-transition>
                </v-list-item-group>
              </v-list>
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <!-- <div class="">
          <v-tooltip v-if="navminivarient" right content-class="tooltip-right">
            <template v-slot:activator="{ on, attrs }" >
              <div v-bind="attrs" v-on="on" class="px-4 mb-2">
                <img width="100%" src="/img/conserve/logo-dark.webp"  alt="back-ground image"/>
              </div>
            </template>
            <span>Powered By - Conserve</span>
          </v-tooltip>
          <div v-else class="text-center px-4">
            <div class="font-weight-bold caption mb-1 line-1">Powered By</div>
            <img src="/img/conserve/logotext-no-bg.webp" width="80%" class="consark-logo-image"  alt="consark-logo-image"/>
          </div>
        </div> -->
      </div>
    </v-navigation-drawer>
    <v-main id="main" v-resize="onResize" v-if="clientload" :key="reloadkey">
      <div class="pa-0 main-container">
        
        <div class="snackbar-container d-flex flex-column">
          <v-snackbar bottom dense left v-for="(sanckbar, index) in $store.state.snackMultValue.filter((s) => s.showing)" 
            :key="index + Math.random()" 
            v-model="sanckbar.showing"
            class="csnackbars pa-0" 
            min-height="0"
            min-width="0"
            elevation="0" 
            :timeout="sanckbar.time">
              <div class="shadow-on">
                <v-alert border="left" dark :type="sanckbar.type" text class="ma-0 white px-3"  dense>
                  <div class="d-flex">
                    <div class="d-flex flex-column justify-center">
                      <span class="body-2">{{sanckbar.text}}</span>
                    </div>
                    <v-btn icon small class="ml-2" :color="sanckbar.type">
                      <v-icon @click="sanckbar.showing=false">mdi-close-circle</v-icon>
                    </v-btn>
                  </div>
                </v-alert>
              </div>
          </v-snackbar>

          <!-- <v-snackbar bottom left v-model="$store.state.sbsuccess" class="csnackbars" color="" elevation="0">
            <div class="">
              <v-alert type="success" class="ma-0 white" border="left" >
                <span class="body-2">{{$store.state.sbsuccessmsg}}</span>
              </v-alert>
            </div>
          </v-snackbar>
          <v-snackbar bottom left v-model="$store.state.sberror" class="csnackbars" color="" elevation="0">
            <div class="">
              <v-alert type="error" text class="ma-0 white" border="left" dense>
                <span class="body-2">{{$store.state.sberrormsg}}</span>
              </v-alert>
            </div>
          </v-snackbar>
          <v-snackbar bottom left v-model="$store.state.sbwarning" class="csnackbars" color="" elevation="0">
            <div class="">
              <v-alert type="warning" class="ma-0 white" border="left" >
                <span class="body-2">{{$store.state.sbwarningmsg}}</span>
              </v-alert>
            </div>
          </v-snackbar>
          <v-snackbar bottom left v-model="$store.state.sbinfo" class="csnackbars" color="" elevation="0">
            <div class="">
              <v-alert type="info" class="ma-0 white" border="left" >
                <span class="body-2">{{$store.state.sbinfomsg}}</span>
              </v-alert>
            </div>
          </v-snackbar> -->
        </div>


        <v-toolbar class="pr-2 main-container-toolbar shadow-on align-center white py-1" style="height:56px" dense ref="toolbar" elevation="0"  background-color="white">
          <lb-divider type="vertical"></lb-divider>

          <template v-if="$vuetify.breakpoint.lgAndUp">
            <v-tooltip v-if="navminivarient" bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }" >
                <v-btn v-bind="attrs" small v-on="on" icon class="mx-2" @click="changeNavVarient(false)"><v-icon>mdi-forwardburger</v-icon></v-btn>
              </template>
              <span>Expand Navigation</span>
            </v-tooltip>
            <v-tooltip v-else bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }" >
                <v-btn v-bind="attrs" small v-on="on" icon class="mx-2" @click="changeNavVarient(true)"><v-icon>mdi-backburger</v-icon></v-btn>
              </template>
              <span>Collapse Navigation</span>
            </v-tooltip>
          </template>

          <div v-if="(($store.state.menu || [])[0] || {}).product && navminivarient" class="d-flex align-center">
            <v-chip :class="`${(($store.state.menu || [])[0] || {}).bgcolor} body-2 white--text mr-3`" label>{{(($store.state.menu || [])[0] || {}).title}}</v-chip>
            <span class="title mr-3">/</span>
          </div>

          <div class="d-flex align-center" v-if="editHeader">
            <span :class="`${$vuetify.breakpoint.mdAndUp?'title':'body-1'} text--lighten-1 pr-3`">Manage </span>
            <lb-string v-model="newHeaderName" label="" hidedetails />
            <v-btn @click="UpdateHeader"  color="primary" class="ml-2" v-bind="attrs" v-on="on">
              Update
            </v-btn>          
          </div>
          <div v-else-if="$route.meta.edit === true" :class="`${$vuetify.breakpoint.mdAndUp?'title':'body-1'} text--lighten-1`">Manage {{$store.state.LocationHeader?$store.state.LocationHeader:'Location'}} 
            <v-btn v-if="$route.meta.edit === true" @click="editHeader=true" icon small class="shadow-off"><v-icon style="font-size:20px !important">mdi-pencil-outline</v-icon></v-btn>
          </div>
          <div v-else :class="`${$vuetify.breakpoint.mdAndUp?'title':'body-1'} text--lighten-1`">{{($route.meta || {}).title || ""}} 
          </div>
            <v-spacer></v-spacer>
          <!-- <img src="/img/consark/logo.png" width="140" class="consark-logo-image mr-6"  alt="consark-logo-image"/> -->
          <div class="">
            <!-- <div class="font-weight-bold">Product Of</div> -->
            <div class="text-center px-4">
              <!-- remove logo<div class="line-1" style="font-size:10px !important">Powered By</div>
              <img src="/img/conserve/logotext-no-bg.webp" width="140" class="consark-logo-image"  alt="consark-logo-image"/> -->
            </div>
          </div>
          <div class="mr-2" v-if="($route.meta || {}).showclients !== false && showHeaderAction">
            <v-chip outlined label class="px-4" v-if="($store.state.clientlist || []).length === 1">
              {{$store.state.clientlist[0].name}}
            </v-chip>
            <lb-dropdown v-else-if="($store.state.clientlist || []).length > 1"
              style="min-width: 200px"
              label="Entity"
              :items="$store.state.clientlist"
              itemnamet="name" itemvalue="_id"
              v-model="selectedclient"
              hidedetails
              :clearable="false"
            />
          </div>

          <!-- Apps & Links list menu -->
          <!-- <v-menu transition="slide-y-transition" bottom :offset-y="true" v-if="showHeaderAction" close-on-content-click>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-apps</v-icon>
              </v-btn>
            </template>
            <v-list width="270" class="py-0">

              <v-divider v-if="linklist.length > 0"></v-divider>
              <v-list-item v-if="linklist.length > 0">
                <v-list-item-content class="text-center">
                  <div class="d-flex">
                    <v-spacer></v-spacer>
                    <template v-for="(v,k) in linklist">
                      <div class="text-center" :key="k">
                        <v-card flat :href="v.link" target="_blank" class="shadow-off">
                          <v-card-text>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar :color="v.logo ? '':(v.color || primary)" size="50" tile class="rounded" v-bind="attrs" v-on="on">
                                  <img v-if="v.logo" :src="v.logo"  :alt="V.logo"/>
                                  <span v-else class="white--text text-h5">{{v.avatartext || "-"}}</span>
                                </v-avatar>
                              </template>
                              <span>{{v.displayname || "No Name"}}</span>
                            </v-tooltip>
                          </v-card-text>
                        </v-card>
                      </div>
                    </template>
                    <v-spacer></v-spacer>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu> -->

        
          <v-menu transition="slide-y-transition" bottom :offset-y="true" v-if="showHeaderAction">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="ml-3">
                <img :src="profileToShow" v-if="profileToShow" alt="" width="40px" height="40px" 
                 class="radius-full" />
                <!-- <img :src="userDetails.profile_url" v-else-if="userDetails.profile_url" alt="" width="40px" height="40px"  class="radius-full" /> -->
                <v-icon v-else>mdi-account-circle-outline</v-icon>
              </v-btn>
            </template>
            <v-list dense style="max-width: 230px !important;" class="pb-0">
              <v-list-item class="pb-2">
                <v-list-item-content class="text-center">
                  <v-list-item-title class="py-1 subtitle-1">
                    {{$store.state.user.name}}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{$store.state.user.email}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <!-- <v-list-item>
                <v-list-item-content class="text-center py-0">
                  <v-list-item-title class="py-3 subtitle-1">
                    <v-btn color="primary" class="shadow-off" block @click="$nova.gotoLink({path: '/home/dashboard'})"><v-icon class="mr-2">mdi-home-outline</v-icon>UCP Home</v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider> -->
              <!-- App list -->
              <template v-if="applist.length > 1">
                <v-list-item>
                  <v-list-item-content class="text-center">
                    <div class="">
                      <template v-for="(v,k) in applist">
                        <v-tooltip bottom  :key="k" content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }" >
                            <div  v-bind="attrs" v-on="on" class="text-center d-inline-block hoverable pa-2 cursor-pointer" @click="$nova.gotoLink({path: v.link})">
                              <v-avatar :class="`${v.color || 'primary'} rounded`" size="50" tile>
                                <img v-if="v.logo" :src="v.logo" :alt="v.logo"/>
                                <span v-else class="white--text text-h5">{{v.avatartext || "-"}}</span>
                              </v-avatar>
                            </div>
                          </template>
                          <span>{{v.displayname || "No Name"}}</span>
                        </v-tooltip>
                      </template>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
              </template>

              <v-list-item  @click="showUserDetials() ">
                <v-list-item-icon class="mr-4"><v-icon dense>mdi-account</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link @click="logout()">
                <v-list-item-icon class="mr-4"><v-icon dense>mdi-logout-variant</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>

        <div v-if="loading > 0" class="d-flex all-height align-center justify-center">
          <loader />
        </div>
        <div v-else class="main-content-holder" :style="{'height': '10px'}">
          <div class="scroller" id="main-route-container">
            <div class="d-flex flex-column">
              <div class="flex-grow-1 py-2">
                <v-fade-transition hide-on-leave>
                  <router-view class=""></router-view>
                </v-fade-transition>
              </div>
            </div>
          </div>
        </div> 
      </div>
      <!-- <div class="fullloadingscreen" v-if="$store,state.loading > 0">Loading</div> -->

      <lb-dialog v-model="EditDetails" heading="My Profile" width="600" persistent >
        <template v-slot:body>
          <v-container class="pa-0">
            <v-form @submit.prevent="updateProfile">
              <v-row class="d-flex align-center justify-center">
                <v-col cols="1" sm="6" class="d-flex align-center justify-center">

                  <v-btn class="fixed-delete-btn border-on " v-if="profileToShow" @click="removeProfilePicture = true ; profileToShow=''"  icon small style="background-color:white"><v-icon>mdi-close</v-icon></v-btn>
                  <div class="d-flex flex-column align-center justify-center">
                    <div class="p-2  border-on cursor-pointer radius-full d-flex  align-center justify-center  "
                      style="height: 80px; width:80px" @click="triggerFileInput">
                      <img :src="profileToShow" v-if="profileToShow" alt="" width="79px" height="79px"
                       class="radius-full" />
                      <!-- <img :src="userDetails.profile_url" v-else-if="userDetails.profile_url" alt="" width="79px" height="79px"
                        class="radius-full" /> -->
                      <v-icon v-else>mdi mdi-camera-plus-outline </v-icon>
                      <input type="file" ref="fileInput" style="display: none;" @change="onFileSelected"
                        accept=".jpg,.jpeg,.png" />
                    </div>
                  </div>

                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1" sm="12" class="py-0">
                  <v-text-field v-model="userDetails.username" dense class="my-0" type="text" label="Name*"  prepend-icon="mdi-account" required
                  :error-messages="errorDetails"   
                ></v-text-field>
                </v-col>
                <v-col cols="1" sm="12" class="py-0">
                  <v-text-field readonly v-model="userDetails.email" label="Email*" prepend-icon="mdi-email-outline" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1" sm="12" class="py-0 d-flex align-center">
                  <v-icon class="pr-3">mdi-phone</v-icon>
                  <vue-country-code class="" :defaultCountry="userDetails.countrycode" style="height: fit-content" @onSelect="getCoutryCode"></vue-country-code>
                  <v-text-field v-model="userDetails.contactno" label="Contact" type="text" required @keydown="restrictInput"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <div class="py-12"></div>
              </v-row>
              <v-row>
                <v-col cols="1" sm="12" class="d-flex align-center justify-center">
                  <v-spacer></v-spacer>
                  <v-btn color="primary"  class="text--white white--text" 
                    type="submit"> {{ userDetails.profile_url?`Update`:`Submit`}}</v-btn>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </template>
      </lb-dialog>

    </v-main>
  </v-app>
</template>

<script>
import loader from './common/Loader';

export default {
  name: "app",
  data: () => ({
    login: false,
    showNavgation: true,
    navminivarient: true,
    showHeaderAction: true,
    containerHeight: 0,
    clientload: false,
    downloaddialog: false,
    activemenu: 0,
    reloadkey: 0,
    display: "load",
    loading: 0,
    routeid: "",
    applist: [
    ],
    linklist: [
      // {name: "outlook", displayname: "Outlook", color: "indigo", avatartext: "A", logo: "/img/tp-apps/outlook.webp", link:"https://login.live.com/login.srf"},
      // {name: "clickup", displayname: "Click Up", color: "indigo", avatartext: "A", logo: "/img/tp-apps/clickup.webp", link:"https://app.clickup.com/login"},
    ],
    appname: "",
    selectedclient: "",
    pathName:"",
    isPlanExpaired: false,
    EditDetails:false,
    userDetails:[],
    profileToShow:"",
    editHeader:false,
    newHeaderName:"",
    errorDetails:'',
    removeProfilePicture:false,
    NewProfle:''
  }),
  components: {
    loader
    // setpassword, forgotpassword,
  },
  created() {
    this.navminivarient = localStorage.getItem("navminivarient")==="true"?true:false;
    if(this.navminivarient !== this.$store.state.mininavbar) this.$store.commit('toogleNavBar');
    this.userDetails={
      username: this.$store.state.user.name || "",
      email: this.$store.state.user.email || "",
      contactno: this.$store.state.user.contactno || "",
      userId: this.$store.state.user.id||"",
      countrycode:this.$store.state.user.countrycode || "",
      profile_url:this.$store.state.user.profile || "",
    }
    this.profileToShow=this.$store.state.user.profile

    // this.setMenu(this.$route.path);
    if(this.$route.path === "/") this.$nova.gotoLink({path: '/home/dashboard'})
    console.log("wertyui", this.$route.meta.title,"werty",this.$store.state.LocationHeader);
    
    this.getDetails();
  },
  mounted(){
    this.selectedclient = this.$route.query.cid || "";
    this.dragFunctions();
    this.onResize();
  },
  methods: {
    showUserDetials(){
        this.EditDetails = true; 
        this.errorDetails='';
        this.userDetails={
        username: this.$store.state.user.name || "",
        email: this.$store.state.user.email || "",
        contactno: this.$store.state.user.contactno || "",
        userId: this.$store.state.user.id||"",
        countrycode:this.$store.state.user.countrycode || "",
        profile_url:this.$store.state.user.profile || "",

      },
      this.NewProfle=''
      this.removeProfilepicture=false
    },
    UpdateHeader(){
      if( this.newHeaderName)
    {
      this.axios.post("v2/client/edit",
        {data:{'location': this.newHeaderName, userlist:[] },
          id: this.selectedclient,
        }).then(dt => {
          if(dt.data.status=='success')
          {
            this.$store.commit("sbSuccess", "Header Updated Successfully")
            this.$store.commit('setLocationHeader',dt.data.data[0].location)
          }
          else
          {
            this.$store.commit("sbError", dt.data.message || "Unknown error!");
          }
          this.newHeaderName = this.$store.state.LocationHeader
        });
        this.getLocationHeader()

        this.editHeader=false
    }
    else
    {
      this.$store.commit("sbError", "Cannot keep the header empty")
    }
    },
    getLocationHeader(){
      this.axios.post("v2/client/getlocation").then(dt => {
        console.log("werftghj",dt);
        this.$store.commit('setLocationHeader',dt.data.data[0].location);
        this.newHeaderName= dt.data.data[0].location||this.$store.state.LocationHeader ||'Location'
        
      })
    },
    restrictInput(event) {
      const key = event.key;
      if (!/^[0-9]$/.test(key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(key)) {
        event.preventDefault();
      }
    },
    changeNavVarient(flag){
      this.$store.commit('toogleNavBar', flag);
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    getCoutryCode({iso2}) {
      this.userDetails.countrycode = iso2
    },
    onFileSelected(event) {
      this.userDetails.profile_url = event.target.files[0];
      if (this.userDetails.profile_url) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.profileToShow = e.target.result;
          this.NewProfle=e.target.result
        };
        reader.readAsDataURL(this.userDetails.profile_url);
      }
    },
    async updateProfile() {
      const now = new Date();
      const epochTime = now.getTime();
      if (!this.$store.state.noauth) {
        let profilePath ='';
        let details = {
          username: this.userDetails.username.trim() ||"",
          contactno:this.userDetails.contactno.trim()||"" ,
          countrycode:this.userDetails.countrycode || "",
          email: this.userDetails.email||"",
          profile_url_expiration :epochTime
        };
        if(this.NewProfle)
        {
          profilePath =await this.$nova.uploadFile(this.axios, this.userDetails.profile_url );
          details ={...details, 
            profile_url: profilePath.data.data[0].path,
            upload_id: profilePath.data.data[0]._id
          }
        }
        else if(this.removeProfilePicture)
        {
          
          const profile_data= {profile_url : this.$store.state.user.profile || this.$store.state.user.profile_url ||this.userDetails.profile_url||""}
        this.axios.post("/v2/userprofile/delete",{data:profile_data}).then(dt => {
         console.log("removed",dt);
         
          this.userDetails.profile_url = "";     
          this.profileToShow=''

        });
      }
        this.axios.post("/v2/userprofile/add", {data:[details]}).then(dt => {
          if(dt.data.status=='success')
          {
            this.$store.commit("sbSuccess", "Profile Updated Successfully")
            this.EditDetails=false
            this.$store.commit("setUser", { name: dt.data.data[0].name, profile: dt.data.data[0].profile_url ,contactno :dt.data.data[0].contactno, countrycode:dt.data.data[0].countrycode });
            
            this.userDetails.profile_url = dt.data.data[0].profile_url
            this.profileToShow=dt.data.data[0].profile_url
          }
          else
          {
            this.errorDetails=dt.data.data[0].index0.name ? dt.data.data[0].index0.name : ""            
          }
          
        });
      }
      },

    getDetails() {
      if(!this.$store.state.noauth){
        this.$store.commit("addLoading");
        this.loading ++;
        let pcodes = [];  
        let rdata = {};      
        this.axios.post("/v2/profile/getdetails").then(dt => {
          
          if(dt.data.status === "success"){
            rdata = (dt.data.data || [])[0] || {};
            let clientdata = rdata.clients || [];
            console.log("wertyui",clientdata, dt);
            
            this.$store.commit("setClientList", clientdata);
            this.$store.commit("refreshClient", false);
            if(clientdata.length > 0) {
              let clientinurl = "";
              if(this.$route.query.cid){
                for (const i of clientdata) {
                  if(i._id === this.$route.query.cid) clientinurl = i._id;
                }
              }
              if(clientinurl) {
                if(clientinurl !== this.selectedclient) this.selectedclient = clientinurl;
              }
              else this.selectedclient = clientdata[0]._id;
            } 
            let subsdata = rdata.subscription || {};
            for (const productSub in subsdata) {
              if (productSub === "financialclose") {
                let data = subsdata.financialclose;
                if (data.plan_detail.name === "Free") {
                  this.isPlanExpaired = data.plan_detail.expiry || false;
                  if (this.isPlanExpaired) {
                    this.$store.state.m_financialclose.menu = [];
                  }
                }
              }
              else if (productSub === "varianceanalysis") {
                let data = subsdata.varianceanalysis;
                if (data.plan_detail.name === "Free") {
                  this.isPlanExpaired = data.plan_detail.expiry || false;
                  if (this.isPlanExpaired) {
                    this.$store.state.m_varianceanalysis.menu = [];
                  }
                }
              }
            }
            if(Object.keys(subsdata).length <= 2) {
              let redirectlink = "";
              let redirectflag = true;
              for (const k in subsdata) {
                if (Object.hasOwnProperty.call(subsdata, k)) {
                  if(k !== 'admin'){
                    const el = subsdata[k];
                    if(redirectlink === "") redirectlink = el?.product_detail?.homepage || "";
                    else redirectflag = false                
                  }
                }
              }
              if(redirectflag) this.$nova.gotoLink({path: redirectlink});
            }
            this.$store.commit("setSubscription", subsdata);            
           
            for (const k in subsdata) {
              if (Object.hasOwnProperty.call(subsdata, k)) {
                const el = subsdata[k];
                pcodes.push(el.product_detail.code);
              }
            }

            // if(pcodes.indexOf('bot') > -1) {
            //   this.$store.commit("setAdmin", true);
            //   this.applist.push({name: "admin", displayname: "Admin", color: "primary", avatartext: "A", logo: "", icon: "mdi mdi-shield-crown-outline", link: "/admin/dashboard"});
            // }else this.$store.commit("setAdmin", false);
            return this.axios.post("/v2/auth/getpermission", {});
          }else{
            this.$store.commit("sbError", "Error fetching client list");
          }
        }).then(dt => {
          if(dt.data.status === "success"){
            if(dt.data.data.length > 0){
              this.$store.commit("setPermission", dt.data.data[0]);
              let perm = dt.data.data[0]
              this.applist = [];
              // {name: "admin", displayname: "Admin", color: "indigo", avatartext: "A", logo: "", link: "/admin/dashboard"},
              // {name: "ia", displayname: "Internal Audit", color: "secondary", avatartext: "IA", logo: "", link: "/ia/dashboard"},
              if(pcodes.indexOf('pbc') > -1 && perm.pbc_project) this.applist.push({name: "pbc", displayname: "Information Workflow", color: "crisk", avatartext: "I", logo: "", icon: "mdi mdi-axis-arrow-info", link: "/pbc/project"});
              if(pcodes.indexOf('tds_ar') > -1 || pcodes.indexOf('tds_ap') > -1 || (pcodes.indexOf('tds_monthly') > -1 && perm.tds_monthly)) this.applist.push({name: "tds", displayname: "Witholding Tax", color: "ccompliance", avatartext: "T", logo: "", icon: "mdi mdi-check-decagram-outline", link: "/tds"});
              if(pcodes.indexOf('earnout') > -1) this.applist.push({name: "earnout", displayname: "Earnouts", color: "ctransaction", avatartext: "E", logo: "", icon: "mdi mdi-bank-transfer", link: "/earnout/project"});
              if(pcodes.indexOf('financialclose') > -1) this.applist.push({name: "financialclose", displayname: "Financial Close", color: "caccounting", avatartext: "F", logo: "", icon:"mdi mdi-book-open", link: "/financialclose/home"});
              if(pcodes.indexOf('ia') > -1) this.applist.push({name: "ia", displayname: "Internal Audit", color: "crisk", avatartext: "IA", logo: "", icon: "mdi mdi-order-bool-ascending-variant", link: "/ia/project"});
              if(pcodes.indexOf('bot') > -1 && perm.bot_project) this.applist.push({name: "bot", displayname: "Bots", color: "crisk", avatartext: "B", logo: "", icon: "mdi mdi-robot-excited-outline", link: "/bot/project"});
              if(pcodes.indexOf('flux') > -1) this.applist.push({name: "flux", displayname: "Flux Management", color: "caccounting", avatartext: "F", logo: "", icon: "mdi mdi-help-box-multiple-outline", link: "/flux/project"});
              if(pcodes.indexOf('helpdesk') > -1) this.applist.push({name: "helpdesk", displayname: "Help Desk", color: "primary", avatartext: "H", logo: "", icon: "mdi mdi-help-box-multiple-outline", link: "/helpdesk/queries"});
              if(pcodes.indexOf('varianceanalysis') > -1) this.applist.push({name: "varianceanalysis", displayname: "Commentary", color: "caccounting", avatartext: "C", logo: "", icon: "", link: "/varianceanalysis/analysis"});
              if(pcodes.indexOf('admin') > -1) this.applist.push({name: "admin", displayname: "Admin", color: "primary", avatartext: "A", logo: "", icon: "mdi mdi-shield-crown-outline", link: "/admin/dashboard"});

              // console.log(dt.data.data[0]);
            }
          }else throw Error("Error fetching permission details");
          this.getLocationHeader()
        }).catch(err => {
          this.$store.commit("sbError", err.message || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.onResize();
          this.clientload = true;
          this.reloadkey += 1;
          this.loading --;
          // this.$nova.checkSubscriptionPermission(this.$route, this.$route);
          this.checkSubscriptionPermission(this.$route, this.$route);
          // else this.setMenu(this.$route.path);
          this.$store.commit("removeLoading");          
        });
      }else {
        this.reloadkey += 1;
        this.clientload = true;
      }
    },
    // checkApps() {
    //   if (this.$route.params.id) {
    //     if (this.$store.state.client.apps[this.$route.params.id]) {
    //       document.title =
    //         this.$store.state.client.apps[this.$route.params.id].displayname ||
    //         "Supernova";
    //       this.display = "ui";
    //       this.routeid = this.$route.params.id;
    //     } else this.display = "apps";
    //   } else {
    //     if (Object.keys(this.$store.state.client.apps).length == 1) {
    //       window.location.href =
    //         "/" +
    //         this.$store.state.client.apps[
    //           Object.keys(this.$store.state.client.apps)[0]
    //         ].name;
    //       // this.$router.push("/" + this.$store.state.client.apps[Object.keys(this.$store.state.client.apps)[0]].name);
    //       this.display = "ui";
    //     } else {
    //       this.display = "apps";
    //     }
    //   }
    //   // console.log(this.display);
    // },
    logout(){
      this.axios.post('/v2/auth/logout', {
      }).then(x => {
        if(x.data.status == "success"){
          this.$store.commit("changeLogin", false);
          this.$store.commit("setUser", null);
          this.$router.push('/');
        }
      }).catch(e => {
        this.$store.commit("sbError", e.message || "Logout error!");
        console.log(e);
        
      })
    },
    setMenu(path) {
      this.appname = "m_" + (path || "").split("/")[1] || "";
      // console.log( this.appname,"FFF");
      let menuitems = [];
      let storeobj = this.$store.state[this.appname] || {};
      if(storeobj.menu) {
        if(this.$store.state.newmenu.length > 0) menuitems = [...this.$store.state.newmenu, ...this.$store.state.custommenu];
        else menuitems = [...this.$store.state[this.appname]?.menu, ...this.$store.state.custommenu];
      }
      else menuitems = [];
      let newmenu = [];
      let dummymenu = [];
      let headerstart = false;
      let menupostsubs = [];
      let addcust = this.$route.meta?.submenu || [];
      let params = this.$route.params;
      // console.log(this.$route);
      for (const i of menuitems) {
        if(i.submenu && addcust.indexOf(i.variable) > -1){
          if(storeobj[i.variable]){
            let nm = [];
            // console.log("in1111")
            for (const j of storeobj[i.variable]) {
              if(j.href){
                let nj = {...j};
                // console.log("in1122", nj.href);
                let replacetexts = nj.href.match(/\${2}\w+[^_]\${2}/g) || [];
                for (const k of replacetexts) {
                  let ttext = k.replaceAll("$", "");
                  // console.log("in2222", k, params[ttext]);
                  if(params[ttext]) nj.href = nj.href.replaceAll(k,params[ttext]);
                  // console.log("post replace", nj.href);
                }
                nm.push(nj)
              }else nm.push(j);
            }
            menupostsubs = [...menupostsubs, ...nm]
          }
        }else menupostsubs.push(i);
      }
      for (const i of menupostsubs) {
        if(i.product) newmenu.push(i);
        else if(i.header){
          if(headerstart){
            if(dummymenu.length > 1) newmenu = [...newmenu, ...dummymenu];
            dummymenu = [];
          }
          dummymenu.push(i);
          headerstart = true;
        }else{
          if(this.$nova.hasRight(i.right, i.action)){
            if(headerstart) dummymenu.push(i);
            else newmenu.push(i);
          }
        }
      }
      if(dummymenu.length > 1) newmenu = [...newmenu, ...dummymenu];
      this.$store.commit("setMenu", newmenu);
    },
    setCustomMenu() {
      // let perm = this.$nova.getPermission('commentary');
      // if(perm.indexOf('admin') > -1 && this.$route.meta.tmenu){
      //   let custommenu = [
      //     { divider: true },
      //     { header: true, title: 'Admin' },
      //     {title: 'Materiality', icon: 'mdi mdi-cash', href: '/varianceanalysis/threshold',},
      //     {title: 'User Access management', icon: 'mdi mdi-layers', href: '/varianceanalysis/accessrights',},
      //     {title: 'GL Selection', icon: 'mdi mdi-order-bool-ascending-variant', href: '/varianceanalysis/glselection',},
      //     ];
      //   this.$store.commit("setCustomMenu", custommenu);
      // }else if(perm.indexOf('approve') > -1 && this.$route.meta.tmenu){
      //   let custommenu = [
      //     { divider: true },
      //     { header: true, title: 'Admin' },
      //     {title: 'Approvals', icon: 'mdi mdi-check-circle', href: '/varianceanalysis/approvals',},
      //   ];
      //   this.$store.commit("setCustomMenu", custommenu);
      // }else this.$store.commit("setCustomMenu", []);
    },
    onResize() {
      setTimeout(() => {
        if(!this.$vuetify.breakpoint.lgAndUp){
          this.$store.commit('toogleNavBar', true);
          this.navminivarient = true;
        }
      }, 500);
      if(this.$refs.toolbar && document.getElementById("main")){
        this.$store.commit("setHeight", (((document.getElementById("main").offsetHeight) - (this.$refs.toolbar.$el.offsetHeight || 0) - 2) || '100vh'));
      }
    },
    getExtImage: function(n){
      let na = n.split(".");
      let ext = na[na.length-1];
      if(ext === "pptx") return "/img/tpa_icons/ppt.jpg";
      else if(ext === "xlsx") return "/img/tpa_icons/excel.jpg";
      else if(ext === "zip") return "/img/tpa_icons/zip.jpg";
      else return "";
    },
    // downloadFile(id, n){
    //     var downloadUrl = 'https://api.lazybit.in/conserve/analysis/getdownloadfile/' + id;
    //     const fileStream = streamSaver.createWriteStream(n);

    //     fetch(downloadUrl, {
    //       method: 'POST',
    //       credentials: 'include'
    //     }).then(res => {
         
    //       const readableStream = res.body

    //       window.writer = fileStream.getWriter()

    //       const reader = res.body.getReader()
    //       // eslint-disable-next-line
    //       const pump = () => reader.read().then(nres => nres.done ? writer.close(): writer.write(nres.value).then(pump))
    //       pump()
    //     }).catch(err => {
    //       console.log(err);
    //     });

    //     // this.axios({method: "POST", url: '/conserve/analysis/getdownloadfile/' + id, responseType: 'blob'}).then(dt => {
    //     // // this.axios({method: "POST", url: '/conserve/analysis/getdownloadfile/' + id, responseType: 'arraybuffer'}).then(dt => {
    //     //   // console.log(dt);
    //     //   let blob = dt.data;
    //     //   // if(dt.headers["content-type"].substring(0,16) != "application/json"){
    //     //   if(dt.data.type != "application/json"){
    //     //     return filesaver.saveAs(blob, n);
    //     //     // .then(() => {
    //     //     // }).catch(err => {
    //     //     //   console.log(err);
    //     //     //   this.$store.commit("sbError", "Error downloading file '" + n + ".xlsx'");
    //     //     // });
    //     //   }else{
    //     //     blob.text().then(dt => {
    //     //       let js = JSON.parse(dt);
    //     //       console.log(js.message)
    //     //       this.$store.commit("sbError", js.message || "Error downloading file " + n);
    //     //     }).catch(err => {
    //     //       this.$store.commit("sbError", "Error downloading file " + n);
    //     //     });
    //     //   }
    //     // }).catch(err => {
    //     //   console.log(err);
    //     //   this.$store.commit("sbError", "Error downloading file " + n);
    //     // }).finally(() => {
    //     //   // this.$store.commit("endDownload", vid);
    //     //   // this.endDownload(vid);
    //     // });
    //   // }
    // },
    dragFunctions(){
      document.addEventListener('dragstart', event => {
        event.target.style.opacity = 0.5;
      });
      document.addEventListener('dragend', event => {
        event.target.style.opacity = 1;
      });
    },
    checkSubscriptionPermission(to, from){
      // console.log("called check");
      if(from.query.cid === to.query.cid) {
        // console.log("called check", this.$nova.hasRight(to.meta.right, to.meta.action));
        let hassub = false;
        let requiredsubs = to.meta.subscription || [];
        let avaliablesubs = this.$store.state.subscriptions;
        for (const i of (requiredsubs)) {
          if(avaliablesubs[i || "xxxxx"] || avaliablesubs[(i || "").toUpperCase() || "xxxxx"]) hassub = true;
        }
        let haspermission = true;
        if(to.meta.right) haspermission = this.$nova.hasRight(to.meta.right, to.meta.action);
        // console.log("called check", hassub, haspermission, to.meta.allow, process.env.VUE_APP_IGNORE_SUBSCRIPTIONS);
        if (!((hassub && haspermission) || to.meta.allow || process.env.VUE_APP_IGNORE_SUBSCRIPTIONS === '1')) {  
          let routeData = {
            path: to.path,
            query: { ...to.query },
            hash: to.hash,
          };
          let oldpath = JSON.stringify(routeData);
          routeData.path = "/home/dashboard";
          let newpath = JSON.stringify(routeData);
          if (oldpath !== newpath) {
            // console.log(from, to);
            if(!hassub) this.$router.push(routeData);
            else this.$router.go(-1);
          }
        }
        this.setCustomMenu();
        this.setMenu(to.path);
      }
    }
    // reloadPage(d){
    //   console.log(this.selectedclient, d);
    //   this.$router.go();
    // },
  },
  // beforeRouteEnter(to, from) {
  //   console.log("route changed");
  //   this.checkSubscriptionPermission(to, from);
  //   return true;
  //   // called before the route that renders this component is confirmed.
  //   // does NOT have access to `this` component instance,
  //   // because it has not been created yet when this guard is called!
  // },
  computed: {
  },
  watch: {
    $route(to, from) {
      this.checkSubscriptionPermission(to, from);
      // console.log(to)
      // this.setCustomMenu();
      // this.setMenu(to.path);
    },
    selectedclient: function(d){
      this.$store.commit("setClient", d);
    },
    '$store.state.client': function(d){
      this.selectedclient = d;
      let routeData = {
        path: this.$route.path,
        query: {...this.$route.query},
        hash: this.$route.hash,
      };
      let oldpath = JSON.stringify(routeData);
      routeData.query.cid = d;
      let newpath = JSON.stringify(routeData);
      if(oldpath !== newpath) {
        this.$router.push(routeData);
      }
    },
    '$store.state.refreshclient': function(d){
      if(d) this.getDetails();
    },
    '$store.state.containerheight': function(d){
      this.containerHeight = d+"px";
    },
    '$store.state.navigation': function(d){
      this.showNavgation = d;
    },
    '$store.state.headeraction': function(d){
      this.showHeaderAction = d;
    },
    '$store.state.custommenu': function(){
      this.setMenu(this.$route.path);
    },
    '$store.state.mininavbar': function(){
      this.navminivarient = this.$store.state.mininavbar;
      localStorage.setItem("navminivarient", this.navminivarient);
    }
  },
};
</script>

<style lang="scss">
  .nav-drawer {
    background-color: transparent !important;
    border-right: 0;
  }

  .navigation-container{
    height: 100%;
  }
  .navigation-container .menu-sidebar-psb{
    flex-grow: 1;
  }

  .ellipsis-on {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .main-container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .main-container .main-content-holder{
    flex-grow: 1;
  }
  .v-data-table__empty-wrapper td{
    font-size: 1.3em !important;
  }
  .scroller{
    height: 100%;
    overflow: auto;
  }
  ::-webkit-scrollbar {
    width: 6px;
    position: absolute;
    right: 5px;
    height:6px
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
    border-radius: 100px;
  }  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.3); 
    border-radius: 100px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,0.2); 
  }

  .main-container .main-content-holder .main-content-holder-psb{
    height: 100%;
  }
  .main-container .main-container-toolbar{
    flex-grow: 0;
    z-index: 100;
    position: sticky;
    background-color: rgba(255,255,255,1) !important;
  }
  .main-container .main-container-toolbar .v-toolbar__content {
    padding-left: 0;
  }
  .snackbar-container{
    position: fixed;
    z-index: 10000;
    overflow: hidden;
    left:5vh;
    bottom:5vh;
  }
  .v-snack:not(.v-snack--absolute){
    height: auto !important;
    position: initial !important;
  }
  .snackbar-container .v-snack__content{
    padding: 0 !important;
  }
  .snackbar-container .v-snack__wrapper{
    margin-top: 0 !important;
  }

  // Scrollbar styles
  .v-navigation-drawer__border{
    display: none;
  }
  .menu-sidebar .sb-link{
    min-height: 32px !important;
    border-radius: 12px !important;
    overflow: hidden;
    transition: padding 0.15s ease;
    padding-left: 5px;
  }
  .menu-sidebar .sb-link-slide:hover{
    padding-left: 7px;
    & .v-icon{
      background-color:white;
      border-radius: 8px;
    }
    // padding-left: 10px !important;
  }
  .menu-sidebar .v-list-item-group .v-list-item--active, .menu-sidebar .v-list-item-group .v-list-item--active:before{
    background-color: #fff !important;
    color: var(--v-primary-base) !important;
    box-shadow: 0 20px 27px 0 rgba(0,0,0,.05)!important;
  }
  .menu-sidebar .v-list-item-group .v-list-item--active .nav-icon{
    background-color: var(--v-primary-base) !important;
    color: #fff !important;
    border-radius: 8px !important;
  }
  .bg-design-box{
    position: absolute;
    z-index: -1000;
  }
  .line-1{
    line-height: 1 !important;
  }
  .line-1-2{
    line-height: 1.2 !important;
  }
  .line-1-4{
    line-height: 1.4 !important;
  }
  .all-height{
    height: 100%;
  }
  .all-width{
    width: 100%;
  }
  .theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle, .v-label{
    color: #333 !important;
  }
  .overflow-card-top{
    position: absolute;
    margin-top: -40px;
    z-index: 100;
  }
  @keyframes bg-design-1-animation{
    0% {bottom: 0;left: -10vw;transform: rotate(20deg);}
    40% {bottom: 2vh;left: -10vw;transform: rotate(23deg);}
    70% {bottom: 5vh;left: -12vw;transform: rotate(18deg);}
    100% {bottom: 0;left: -10vw;transform: rotate(20deg);}
  }
  .bg-design-1{
    // animation-name: bg-design-1-animation; 
    animation-duration: 15s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    background-color: rgba(252, 99, 107, 0.2);
    height: 50vh;
    width: 20vw;
    bottom: -10vh;
    border-radius: 25px;
    left: -10vw;
    transform: rotate(20deg);
    z-index: 0;
  }
  @keyframes bg-design-2-animation{
    0% {bottom: -10vh;left: -15vw;transform: rotate(40deg);}
    40% {bottom: -12vh;left: -15vw;transform: rotate(45deg);}
    70% {bottom: -8vh;left: -19vw;transform: rotate(35deg);}
    100% {bottom: -10vh;left: -15vw;transform: rotate(40deg);}
  }
  .bg-design-2{
    // animation-name: bg-design-2-animation;
    animation-duration: 12s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    background-color: var(--v-primary-base);
    opacity: 0.2;
    height: 40vh;
    width: 30vw;
    bottom: -15vh;
    border-radius: 25px;
    left: -15vw;
    transform: rotate(40deg);
    z-index: 0;
  }
  @keyframes bg-design-3-animation{
    0% {top: -45vh;}
    40% {top: -40vh;}
    70% {top: -50vh;}
    100% {top: -45vh;}
  }
  .bg-design-3{
    // animation-name: bg-design-3-animation;
    animation-duration: 22s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    // background-color: rgba(46, 182, 125, 0.2);
    height: 100vh;
    width: 60vw;
    top: -45vh;
    border-radius: 25px;
    right: -40vw;
    transform: rotate(-40deg);
    z-index: 0;
  }
  .v-text-field--outlined.v-input--dense .v-label--active {
    transform: translateY(-12px) scale(0.75) !important;
  }
  .v-text-field--outlined.v-input--dense .v-label {
    top: 4px;
  }
  .v-text-field .v-input__control .v-input__slot, .v-text-field .v-input__control .v-select__slot {
    padding: 0px;
    min-height: 32px !important;
    /* max-height: 32px !important; */
    display: flex !important;
    align-items: center !important;
  }
  .v-menu__content .v-list-item__action {
    margin-right: 15px !important;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .v-input--dense .v-input--selection-controls__input{
    margin-right: 0px !important;
  }
  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner, 
  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner{
    margin-top: 4px !important;
  }
  /* hide the "scrim", it's pointless */
  .v-overlay--active .v-overlay__scrim {
    display: none;
  }
  /* style the overlay container as required */
  .v-overlay--active {
    backdrop-filter: blur(2px);
    background: rgb(0 0 0 / 0.4);
  }
  .bgcolor-primary-20{
    background-color: rgba(25, 52, 65, 0.2);
  }
  .bgcolor-crisk-20{
    background-color: rgba(46, 182, 125, 0.2);
  }
  .bgcolor-ccompliance-20{
    background-color: rgba(252, 99, 107, 0.2);
  }
  .bgcolor-caccounting-20{
    background-color: rgba(	106, 103, 206, 0.2);
  }
  .bgcolor-ctransaction-20{
    background-color: rgba(66, 133, 244, 0.2);
  }

  .csnackbars > div{
    background-color: transparent !important;
  }
  table {
    border-collapse: collapse !important;
  }
  .v-data-table--dense td, .v-data-table--dense th{
    padding: 3px 5px !important;
  }
  .v-data-table tbody {
    tr:hover:not(.hoverable-off){
      background-color: rgba(30, 58, 74,0.04) !important;
    }
  }
  // .hoverable-off:hover {
  //   background-color: initial !important;
  // }
  .hoverable:hover{
    background-color: rgba(30, 58, 74,0.04) !important;
  }
  .cursor-pointer{
    cursor: pointer;
  }
  // For Drag and drop
  .cursor-drag {
    cursor: move; /* fallback if grab cursor is unsupported */
  }
  .cursor-drag:focus{
    background-color: #000 !important;
    color: #aaa;
  }
  .text-transform-none{
    text-transform: none !important;
  }

  .runningloader{
    position: relative;
    display: inline-block;
    height:8px;
    width: 8px;
  }
  .runningloader .inner-circle, .runningloader .outer-circle{
    background-color: rgba(230,0,0,1);
    border-radius: 100%;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .runningloader .outer-circle{
    left: 0;
    top: 0;
    animation: runninganimation 1s infinite cubic-bezier(.1, .51, .1, .51);
  }
  @keyframes runninganimation {
    0% {
      transform: scale(1, 1);
      background: rgba(230,0,0,1);
    }
    100% {
      transform: scale(3, 3);
      background: rgba(230,0,0,0.1);
    }
  }


  .view-on-hover-area .view-on-hover-item {
    visibility: hidden;
  }
  .view-on-hover-area:hover .view-on-hover-item {
    visibility: initial;
  }
  .view-on-hover-area:hover .hide-on-hover-item {
    visibility: hidden;
  }
  .view-on-hover-area .view-on-hover-item-d {
    display: none;
  }
  .view-on-hover-area:hover .view-on-hover-item-d {
    display: initial;
  }
  .view-on-hover-area:hover .hide-on-hover-item-d {
    display: none;
  }
  .border-left-ccompliance{
    border-left: 4px solid var(--v-ccompliance-base) !important;
    border-left-color: var(--v-ccompliance-base) !important;
  }
  .border-left-crisk{
    border-left: 4px solid var(--v-crisk-base) !important;
    border-left-color: var(--v-crisk-base) !important;
  }
  .border-left-caccounting{
    border-left: 4px solid var(--v-caccounting-base) !important;
    border-left-color: var(--v-caccounting-base) !important;
  }
  .border-left-ctransaction{
    border-left: 4px solid var(--v-ctransaction-base) !important;
    border-left-color: var(--v-ctransaction-base) !important;
  }
  .border-left-transparent{
    border-left: 4px solid transparent !important;
    border-left-color: transparent !important;
  }
  .border-left-success{
    border-left: 4px solid var(--v-success-base) !important;
    border-left-color: var(--v-success-base) !important;
  }
  .border-left-error{
    border-left: 4px solid var(--v-error-base) !important;
    border-left-color: var(--v-error-base) !important;
  }
  .border-left-default{
    border-left: 4px solid var(--v-default-base) !important;
    border-left-color: var(--v-default-base) !important;
  }
  .border-left-warning{
    border-left: 4px solid var(--v-warning-base) !important;
    border-left-color: var(--v-warning-base) !important;
  }
  .border-left-primary{
    border-left: 4px solid var(--v-primary-base) !important;
    border-left-color: var(--v-primary-base) !important;
  }
  .border-left-info{
    border-left: 4px solid var(--v-info-base) !important;
    border-left-color: var(--v-info-base) !important;
  }
  .border-left-grey{
    border-left: 4px solid rgba(0,0,0,0.2) !important;
    border-left-color: rgba(0,0,0,0.2) !important;
  }
  .border-left-grey-thin{
    border-left: 1px solid rgba(0,0,0,0.2) !important;
    border-left-color: rgba(0,0,0,0.2) !important;
  }
  ::-webkit-scrollbar {
    width: 6px;
    position: absolute;
    right: 5px;
    height:6px
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
    border-radius: 100px;
  }  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.3); 
    border-radius: 100px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,0.2); 
  }
  .radius-small
  {
    border-radius: 8px;
  }

  .vue-country-select {
    border: none !important;
  }
  
  .vue-country-select .dropdown-list {
    width: 250px !important;
    height: 170px !important;
  }
  .radius-full{
    border-radius: 50%;
  }
  .fixed-delete-btn{
    position: absolute;
    top: 8.3vh;
    right: 26vh;
  }

  .v-text-field--enclosed .v-input__append-inner
  {
    margin: auto !important ;
  }

</style>