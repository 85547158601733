var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"app"}},[_c('div',{staticClass:"bg-design-box bg-design-2"}),_c('div',{staticClass:"bg-design-box bg-design-3"}),_c('v-main',{staticClass:"bg-image"},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6","lg":"4"}},[_c('div',{staticClass:"text-center mb-4"},[_c('a',{attrs:{"href":"//consark.ai"}},[_c('img',{staticStyle:{"max-width":"300px"},attrs:{"src":"/img/consark/logo.png","alt":"consark-logo"}})]),_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Unified Controllership Platform ")])]),(_vm.alreadylogin)?_c('div',[_c('loader')],1):(_vm.logintype === 'okta')?_c('div',[_c('v-btn',{attrs:{"color":"primary ma-0","block":"","elevation":"0"},on:{"click":function($event){return _vm.oktaLogin()}}},[_vm._v("Login using Okta")])],1):(_vm.logintype === 'normal')?_c('div',[_c('v-card',{class:{
                'mb-4 shadow2-a pb-1 px-1': true,
                'px-3': _vm.$vuetify.breakpoint.smAndUp,
              },attrs:{"loader-height":"2","loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-text',{staticClass:"mt-3"},[(_vm.msg != '')?_c('v-alert',{staticClass:"py-2",attrs:{"type":"error","border":"left","text":""}},[_vm._v(_vm._s(_vm.msg))]):_vm._e(),_c('lb-string',{attrs:{"label":"Username/Email","prependiconinner":"mdi-shield-account"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{attrs:{"type":"password","label":"Password","dense":"","outlined":"","prepend-inner-icon":"mdi-key-variant","prefix":"","suffix":"","hide-details":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"text-right mt-2"},[_c('v-btn',{staticClass:"shadow-off",attrs:{"text":"","small":"","to":"/forgotpassword"}},[_vm._v("Forgot Password?")]),_c('v-btn',{staticClass:"shadow-off",attrs:{"text":"","small":"","to":"/selfservice"}},[_vm._v("Self Service")])],1)],1)],1),_c('div',{},[_c('v-btn',{attrs:{"color":"primary ma-0","block":"","elevation":"0"},on:{"click":function($event){return _vm.submitLogin()}}},[_vm._v("Login securely")])],1)],1):_c('div',[_c('v-card',{class:{
                'mb-4 shadow2-a pb-1 px-1': true,
                'px-3': _vm.$vuetify.breakpoint.smAndUp,
              },attrs:{"loader-height":"2","loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-text',{staticClass:"mt-3"},[(_vm.msg != '')?_c('v-alert',{staticClass:"py-2",attrs:{"type":"error","border":"left","text":""}},[_vm._v(_vm._s(_vm.msg))]):_vm._e()],1)],1)],1),_c('div',{staticClass:"text-center mt-4"},[_c('div',{staticClass:"text-center mt-2"},[_c('a',{attrs:{"href":"https://consark.ai/policies/termsofuse/","target":"_blank"}},[_c('v-btn',{staticClass:"shadow-off primary--text mr-2",attrs:{"outlined":"","small":""}},[_vm._v("Terms of Use & Service")])],1),_c('a',{attrs:{"href":"https://consark.ai/policies/privacypolicy/","target":"_blank"}},[_c('v-btn',{staticClass:"shadow-off primary--text",attrs:{"outlined":"","small":""}},[_vm._v("Privacy Policy")])],1)])])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }