import Vue from 'vue';
import VueRouter from 'vue-router';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import store from '../store';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

import base from '../views/base.vue';

// import HomeRouter from '../modules/home/route'

Vue.use(VueRouter);
Vue.use(PerfectScrollbar);

// const routes = [
//   {
//     path: '/:id',
//     component: base,
//     children: [
//       {
//         path: ':p/*',
//         component: function(){
//           return getView();
//         }
//       },
//       {
//         path: ':p?',
//         component: function(){
//           return getView();
//         }
//       },
//       // {
//       //   path: ':p/*',
//       //   component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
//       // },
//     ],
//   },
//   {
//     path: '',
//     component: base,
//   },
//   // {
//   //   path: '/about',
//   //   name: 'about',
//   //   // route level code-splitting
//   //   // this generates a separate chunk (about.[hash].js) for this route
//   //   // which is lazy-loaded when the route is visited.
//   //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   // }
// ]

const routes = [
  {
    path: '',
    component: base,
    meta: {noauth: true}
  },
  {
    path: '/setpassword',
    meta: { color: "primary", noauth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/SetPassword.vue'),
  },
  {
    path: '/setnewpassword',
    meta: { color: "primary", noauth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/SetNewPassword.vue'),
  },
  {
    path: '/forgotpassword',
    meta: { color: "primary", noauth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/ForgotPassword.vue'),
  },
  {
    path: '/selfservice',
    meta: { color: "primary", noauth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/SelfService.vue'),
  },
  // {
  //   path: '/admin',
  //   component: base,
  //   children: [
  //     getRouteData("UCP", "user", 'views/Admin/User.vue', { title: "Users", showclients: false }),
  //     getRouteData("UCP", "client", 'views/Admin/Client.vue', { title: "Clients", showclients: false }),
  //     getRouteData("UCP", "client/id/:id", 'views/Admin/ClientDetails.vue', { title: "Client Details", showclients: false }),
  //     getRouteData("UCP", "*", '', {}, undefined, true),
  //     // {
  //     //   path: 'user',
  //     //   component: () => import(/* webpackChunkName: "about" */ '../views/Admin/User.vue'),
  //     //   meta: {title: "Users", showclients: false}
  //     // },
  //     // {
  //     //   path: 'client',
  //     //   component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Client.vue'),
  //     //   meta: {title: "Clients", showclients: false}
  //     // },
  //     // {
  //     //   path: 'client/id/:id',
  //     //   component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ClientDetails.vue'),
  //     //   meta: {title: "Client Details", showclients: false}
  //     // },
  //     // // {
  //     // //   path: 'engagement',
  //     // //   component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Engagement.vue'),
  //     // //   meta: {title: "Engagements"}
  //     // // },
  //     // {
  //     //   path: '*',
  //     //   component: () => import(/* webpackChunkName: "about" */ '../views/common/NoAccess.vue')
  //     // },
  //   ],
  // },
  // {
  //   path: '/ia',
  //   component: base,
  //   children: [
  //     {
  //       path: 'dashboard',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/IA/Dashboard.vue'),
  //       meta: {title: "Dashboard"}
  //     },
  //     // {
  //     //   path: 'engagement/add',
  //     //   component: () => import(/* webpackChunkName: "about" */ '../views/IA/Engagement/AddEdit.vue'),
  //     //   meta: {title: "Add Engagement", type: "add"}
  //     // },
  //     // {
  //     //   path: 'engagement/edit',
  //     //   component: () => import(/* webpackChunkName: "about" */ '../views/IA/Engagement/AddEdit.vue'),
  //     //   meta: {title: "Edit Engagement", type: "edit"}
  //     // },
  //     {
  //       path: 'engagement/view',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/IA/Engagement/View.vue'),
  //       meta: {title: "Engagement Progress"}
  //     },
  //     {
  //       path: 'engagement/setup',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/IA/Engagement/Setup.vue'),
  //       meta: {title: "Engagement Setup"}
  //     },
  //     // {
  //     //   path: 'engagement',
  //     //   component: () => import(/* webpackChunkName: "about" */ '../views/IA/Engagement/List.vue'),
  //     //   meta: {title: "Engagements"}
  //     // },
  //     {
  //       path: 'assignment/view/:id',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/IA/Assignment/View.vue'),
  //       meta: {title: "Assignement Progress"}
  //     },
  //     {
  //       path: 'assignment/setup/:id',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/IA/Assignment/Setup.vue'),
  //       meta: {title: "Assignment Details"}
  //     },
  //     {
  //       path: '*',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/common/NoAccess.vue')
  //     },
  //   ],
  // },
  // {
  //   path: '/pbc',
  //   component: base,
  //   children: [
  //     getRouteData("PBC", "project", 'views/PBC/ProjectList.vue', { title: "Project List" }),
  //     getRouteData("PBC", "project/view/:id", 'views/PBC/ProjectView.vue', { title: "Project Details" }),
  //     getRouteData("UCP", "requestee/view/:client/:code", 'views/PBC/ProjectListRequestee.vue', { title: "Dashboard" }, () => { store.commit("setNewMenu", []) }),
  //     getRouteData("UCP", "requestee/project/:client/:code/:project", 'views/PBC/ProjectViewRequestee.vue', { title: "Request List" }, () => { store.commit("setNewMenu", []) }),
  //     getRouteData("PBC", "*", '', {}, undefined, true),
  //     // {
  //     //   path: 'project',
  //     //   component: function(){
  //     //     return getView("PBC", 'views/PBC/ProjectList.vue');
  //     //   },
  //     //   meta: {title: "Project List"}
  //     // },
  //     // {
  //     //   path: 'project/view/:id',
  //     //   component: function(){
  //     //     return getView("PBC", 'views/PBC/ProjectView.vue');
  //     //   },
  //     //   meta: {title: "Project Details"}
  //     // },
  //     // {
  //     //   path: 'requestee/view/:client/:code',
  //     //   component: function(){
  //     //     store.commit("setNewMenu", []);
  //     //     return getView("PBC", 'views/PBC/ProjectListRequestee.vue');
  //     //   },
  //     //   meta: {title: "Dashboard"}
  //     // },
  //     // {
  //     //   path: 'requestee/project/:client/:code/:project',
  //     //   component: function(){
  //     //     store.commit("setNewMenu", []);
  //     //     return getView("PBC", 'views/PBC/ProjectViewRequestee.vue');
  //     //   },
  //     //   meta: {title: "Request List"}
  //     // },
  //     // {
  //     //   path: '*',
  //     //   component: function() {
  //     //     store.commit("setNewMenu", []);
  //     //     return import(/* webpackChunkName: "about" */ '../views/common/NoAccess.vue');
  //     //   },
  //     // },
  //   ],
  // },
  // {
  //   path: '/tds',
  //   component: base,
  //   otherdata: { color: "ccompliance" },
  //   children: [
  //     getRouteData("TDS", "ar", 'views/TDS/ARList.vue', { title: "AR Analysis List" }),
  //     getRouteData("TDS", "ar/view/:id", 'views/TDS/ARView.vue', { title: "AR Analysis Details" }),
  //     // {
  //     //   path: 'project/view/:id',
  //     //   component: function(){
  //     //     return getView("PBC", 'views/PBC/ProjectView.vue');
  //     //   },
  //     //   meta: {title: "Project Details"}
  //     // },
  //     getRouteData("TDS", "*", '', {}, () => {
  //       // store.commit("setNewMenu", []);
  //     }, true),
  //     // {
  //     //   path: '*',
  //     //   component: function() {
  //     //     store.commit("setNewMenu", []);
  //     //     return import(/* webpackChunkName: "about" */ '../views/common/NoAccess.vue');
  //     //   },
  //     // },
  //   ],
  // },
  // {
  //   path: '/earnout',
  //   component: base,
  //   children: [
  //     getRouteData("earnout", "project", 'views/Earnout/ProjectList.vue', { title: "Project List" }),
  //     getRouteData("earnout", "project/view/:id", 'views/Earnout/ProjectView.vue', { title: "Project Details" }),
  //     getRouteData("earnout", "project/assignment/:id", 'views/Earnout/AssignmentView.vue', { title: "Earnout Details" }),
  //     getRouteData("earnout", "*", '', {}, undefined, true),
  //   ],
  // },
  {
    path: '/error',
    component: base,
    children: [
      getRouteData("UCP", [], "404", 'views/common/NoAccess.vue', {}, () => {
        store.commit("setNewMenu", []);
      }),
      // {
      //   path: '404',
      //   component: function() {
      //     store.commit("setNewMenu", []);
      //     return import(/* webpackChunkName: "about" */ '../views/common/NoAccess.vue');
      //   },
      // },
    ],
  },
  // {
  //   path: '/tds',
  //   component: base,
  //   children: [
  //     {
  //       path: 'monthly/:year/:month',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/TDS/MonthlyRun.vue')
  //     },
  //     {
  //       path: 'ar',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/TDS/AccountReceivables.vue')
  //     },
  //     {
  //       path: 'ar/:id',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/TDS/AccountReceivablesView.vue')
  //     },
  //     {
  //       path: 'ap',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/TDS/AccountPayable.vue')
  //     },
  //     {
  //       path: 'ap/:id',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/TDS/AccountPayableView.vue')
  //     },
  //     {
  //       path: 'monthly',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/TDS/Monthly.vue')
  //     },
  //     {
  //       path: 'mastervendor',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/TDS/MasterVendor.vue')
  //     },
  //     {
  //       path: '*',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/common/NoAccess.vue')
  //     },
  //   ],
  // },
  //  {
  //   path: '/home',
  //   component: base,
  //   children: [
  //     getRouteData("UCP", "dashboard", 'views/Home/Dashboard.vue', { title: "Unified Controllership Platform" }),
  //     getRouteData("UCP", "profile", 'views/Home/Profile.vue', { title: "Profile Info" }),
  //     getRouteData("UCP", "*", '', {}, undefined, true),
  //     // {
  //     //   path: 'dashboard',
  //     //   component: () => import(/* webpackChunkName: "about" */ '../views/Home/Dashboard.vue'),
  //     //   meta: {title: "Unified Controllership Platform"}
  //     // },
  //     // {
  //     //   path: 'profile',
  //     //   component: () => import(/* webpackChunkName: "about" */ '../views/Home/Profile.vue'),
  //     //   meta: {title: "Profile Info"}
  //     // },
  //     // {
  //     //   path: '*',
  //     //   component: () => import(/* webpackChunkName: "about" */ '../views/common/NoAccess.vue')
  //     // },
  //   ],
  // },
  // ...HomeRouter,
  // {
  //   path: '*',
  //   component: base,
  //   children: [
  //     getRouteData("UCP", "", '', {}, undefined, true),
  //   ],
  // },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function getView(p, view, moduleName) {
  // console.log(p, store.state.subscriptions);
  // console.log(import("./modules/" + view),"SSSSSS");
  // console.log(store.state.subscriptions, subscription, p, allow, process.env.VUE_APP_IGNORE_SUBSCRIPTIONS);
  if(view === "__base") return import('../views/base.vue');
  else if(view === "") return import('../views/common/NoAccess.vue');
  else if(view.indexOf("common/") > -1) { return import("../views/" + view);}
  else return import("../modules/" + moduleName + "/views/" + view);
}

function getRouteData(moduleName, p, path, view = "", meta = {}, cfun = () => { }, noaccess = false) {
  cfun();
  let v = view;
  if (noaccess) {
    store.commit("setNewMenu", []);
    v = "";
  }
  let d = {
    path: path,
    component: () => {
      // console.log(path);
      return getView(p, v, moduleName);
    },
    meta: meta,
  };
  if (["risk"].indexOf(p) > -1) d.meta.color = "crisk";
  else if (["compliance"].indexOf(p) > -1) d.meta.color = "ccompliance";
  else if (["accounting"].indexOf(p) > -1) d.meta.color = "caccounting";
  else if (["transaction"].indexOf(p) > -1) d.meta.color = "ctransaction";
  else if (["ucp", "others"].indexOf(p) > -1) d.meta.color = "primary";
  else d.meta.color = "primary";
  return d;
}

export default {router, getRouteData}

// function getView(p, view) {
//   if (store.state.subscriptions[p || "xxxxx"] || p === "UCP") return import("../" + view);
//   else {
//     let routeData = {
//       path: router.currentRoute.path,
//       query: { ...router.currentRoute.query },
//       hash: router.currentRoute.hash,
//     };
//     let oldpath = JSON.stringify(routeData);
//     routeData.path = "/home/dashboard";
//     let newpath = JSON.stringify(routeData);
//     if (oldpath !== newpath) router.push(routeData);
//   }
// }

// function getRouteData(p, path, view, meta, cfun = () => { }, noaccess = false) {

//   cfun();
//   let v = view;
//   if (noaccess) {
//     store.commit("setNewMenu", []);
//     v = "views/common/NoAccess.vue";
//   }
//   let d = {
//     path: path,
//     component: function () {
//       console.log(path);
//       return getView(p, v);
//     },
//     meta: meta,
//   };  
//   if (["PBC"].indexOf(p) > -1) d.meta.color = "crisk";
//   else if (["TDS"].indexOf(p) > -1) d.meta.color = "ccompliance";
//   else if (["earnout"].indexOf(p) > -1) d.meta.color = "ctransaction";
//   else d.meta.color = "primary";
//   return d;
// }


/*---
To dynamically define the view for project based on json file
---*/
// let id = "";
// let pat = "";
// import routeList from "./routes.json";
// router.beforeEach((to, from, next) => {
//   console.log("route changed");
//   if(to){
//     if(to.params){
//       if(to.params.id) id = to.params.id;
//       if(to.params.p) pat = to.params.p;
//     }
//   }
//   next();
// });

// function getView(){
//   let nid = id;
//   let npat = pat;
//   let r = null;
//   console.log(routeList);
//   if (routeList[nid]) {
//     if (routeList[nid][npat]) {
//       r = routeList[nid][npat];
//     }
//   }
//   if (r) return import('@/views' + r);
//   else return import(/* webpackChunkName: "about" */ '@/views/common/NoAccess.vue');
// }