import { render, staticRenderFns } from "./ActivityLogNew.vue?vue&type=template&id=991e679a&scoped=true&"
import script from "./ActivityLogNew.vue?vue&type=script&lang=js&"
export * from "./ActivityLogNew.vue?vue&type=script&lang=js&"
import style0 from "./ActivityLogNew.vue?vue&type=style&index=0&id=991e679a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "991e679a",
  null
  
)

export default component.exports