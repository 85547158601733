<template>
  <div class="d-inline-block">
    <v-tooltip bottom :content-class='contentClass'>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" name="activity-log" @click.stop="dialog = true;loadData();" icon small> <v-icon>mdi-history</v-icon></v-btn>
      </template>
      <span>Activity Log</span>
    </v-tooltip>
    <lb-dialog v-model="dialog" :heading="heading" :width="width" :loading="loading">
      <template v-slot:body>
        <div v-if="loglist.length > 0">
          <div v-for="(v, k) in loglist" :key="k">
            <div class="d-flex pr-2 pl-0 hoverable align-center">
              <div class="pr-3">
                <div class="d-flex flex-column align-center">
                  <div class="d-inline-block timeline-line grey lighten-2 caption">&nbsp;</div>
                  <v-icon class="grey--text">mdi-arrow-up</v-icon>
                  <!-- <v-avatar size="7" color="grey"></v-avatar> -->
                  <div class="d-inline-block timeline-line grey lighten-2 caption">&nbsp;</div>
                </div>
              </div>
              <div class="subtitle-1 line-1">
                <v-icon :color="(config[v.type] || {}).color">{{(config[v.type] || {}).icon || 'mdi-check'}}</v-icon>
                <!-- <v-icon>{{(config[v.type] || {}).icon || 'mdi-check'}}</v-icon> -->
              </div>
              <div class="ml-2 line-1-2">
                <!-- <div small label class="font-weight-bold mb-1">{{v.type}} - {{v.text || (config[v.type] || {}).displaytext}}</div> -->
                <div small label class="font-weight-bold mb-1"> {{v.text || (config[v.type] || {}).displaytext || v.type}}</div>
                <div style="font-size:0.65rem !important;">{{$nova.formatDateTime((new Date(v.created_at)), "DD MMM yyyy hh:mm A")}}</div>
              </div>
              <v-spacer></v-spacer>
              <div class="subtitle-1 line-1">
                <v-icon>mdi-account</v-icon>
              </div>
              <div class="ml-2 line-1">
                <div class="">{{(v.userid_detail || {}).name}}</div>
                <div class="caption">{{(v.userid_detail || {}).contactemail}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="grey--text py-4 text-center body-1" v-else>
          No Log Available
        </div>
      </template>
    </lb-dialog>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      dialog: false,
      loading: false,
      loglist: [],
    };
  },
  // created() {
  //   this.refreshData();
  // },
  // activated() {
  //   this.refreshData();
  // },
  props: {
    heading: {
      type: String,
      default: 'Activity Log',
    },
    width: {
      type: String,
      default: '600',
    },
    url: {
      type: String,
    },
    config: {
      default: {}
    },
    contentClass:{
      type:String,
      default:'tooltip-bottom'
    }
  },
  methods:{
    loadData() {
      this.loading = true;
      this.loglist = [];
      if(this.url){
        this.axios.post(this.url).then(dt => {
          if(dt.data.status === "success"){
            this.loglist = dt.data.data;
            // console.log(this.loglist);
          }else throw new Error ("Error loading information");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.loading = false;
        })
      }
    },
  }
};
</script>

<style scoped>
.timeline-line {
  min-width: 3px;
  max-width: 3px;
}
</style>

