<template>
  <div>
    <!-- <span @click="openDialog(true)">
      <slot name="button" />
    </span> -->
    <v-dialog v-model="value" :max-width="width" scrollable persistent>
      <v-card :loading="loading" :disabled="loading" loader-height="2">
        <v-card-title class="primary align-center py-2 px-4">
          <span class="subtitle-2 white--text">{{ heading }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="openDialog(false)" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pt-8">
          <slot name="body" />
        </v-card-text>
        <v-card-actions class="py-4 px-6">
          <slot name="actions" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogBox",
  data: function(){
    return {
      dialog: false,
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  created(){
    this.dialog = this.value;
  },
  activated(){
    this.dialog = this.value;
  },
  methods: {
    openDialog(v) {
      this.$emit("input", v);
      this.$emit("change", v);
    },
  },
  watch: {
    dialog(v) {
      this.openDialog(v);
    },
    value(v) {
      this.dialog = v;
    }
  }
};
</script>
